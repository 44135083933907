.midiaIndice {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    padding-bottom: 0.25rem;
}

.funilOportunidadeID {
    display: flex;
    justify-content: center;
    align-items: center;
}

.funilOportunidadeNome,
.funilOportunidadeID {
    font-weight: bolder;
}

.funilOportunidadeEmail,
.funilOportunidadeTelefone {
    font-size: 12px;
    color: #5a5959;
}

.funilOportunidadeData {
    display: flex;
    justify-content: center;
    align-items: center;
}

.funilOportunidadeicones {
    font-size: 20px;
    width: 8rem;
    padding-top: 0.5rem;
    display: flex;
    /* justify-content: space-around; */
    align-items: flex-end;
}
.funilOportunidadeicones svg{
    border: 1px solid;
    margin: 4px ;
    padding: 3px;
    border-radius: 20%;
}
.funilOportunidadeicones svg:hover{
    color:white;
    background-color: #444444;
}
.tabelaFunil .table-responsive{
    cursor: auto;
}
.funilOportunidadeDropdown {
    display: flex;
    /* background-color: var(--dommus-color) !important; */
    border-color: transparent !important;
    box-shadow: unset !important;
}

.funilOportunidadeOpcoes .dropdown-toggle::after {
    display: none !important;
}

.midiaIndice,
.funilOportunidadeID,
.funilOportunidadeNome{
    display: flex;
    flex-direction: row;
    gap: '10px';
    cursor:pointer;
}

.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
  }
  
  /* Tooltip text */
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
   
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
  }
  
  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

.highcharts-drilldown-axis-label{
    fill: #444 !important;
    color: #444 !important;
    text-decoration: none !important;
}

.highcharts-label text{
    fill: #444;
    color: #444;
    text-decoration: none !important;
}

.checkbox-funil{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.checkbox-funil label{
    margin-bottom: 0 !important;
    padding-left: 0 !important;
    padding-bottom: 0 !important;
    font-weight: 500 !important;
}

.texto-grafico-normal {
    font-size: 10pt;
    font-weight: 500!important;
}

.texto-cinza {
    color: rgb(147 146 146);
    fill: rgb(147 146 146);
}