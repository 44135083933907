.statusModal {
    background-color: #bae503;
    height: 33px;
    color: #fff;
    font-weight: bold;
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px 10px;
    width: 320px;
}

.substatusModal {
    background-color: #bae503;
    height: 33px;
    color: #fff;
    font-weight: bold;
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px 10px;
    width: 320px;
}

.formStatus .row {
    justify-content: center;
    align-items: center;
    display: flex;
}

.formStatus .formFooter {
    border-top: 1px solid #cccc;
    border-bottom: 0;
    padding-top: 10px;
}

.formStatus .formFooter .col {
    justify-content: flex-end;
    align-items: center;
    display: flex;
}

.formStatus .statusAtivo {
    border: 2px solid #007bff;
}

.formStatus .substatusAtivo {
    border: 2px solid #007bff;
}

.estiloNenhumSub {
    background-color: #fdd8ab;
    padding: 5px 28px;
    color: #5c3e19;
    border-radius: 15px;
    font-size: 14px;
}

.statusBoxAtivo {
    padding: 5px;
    color: #fff;
    font-weight: bold;
    border-radius: 5px;
    margin-bottom: 5px;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    border: 2px solid #007bff;
    cursor: pointer;
}

.statusBox {
    padding: 5px;
    color: #fff;
    font-weight: bold;
    border-radius: 5px;
    margin-bottom: 5px;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
}

.subStatusBoxAtivo {
    padding: 5px;
    color: #fff;
    font-weight: bold;
    border-radius: 5px;
    margin-bottom: 5px;
    width: 95%;
    justify-content: center;
    align-items: center;
    display: flex;
    border: 2px solid #007bff;
    cursor: pointer;
}

.subStatusBox {
    padding: 5px;
    color: #fff;
    font-weight: bold;
    border-radius: 5px;
    margin-bottom: 5px;
    width: 95%;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
}