.btns-status {
  margin-left: 20px;
}

.btns-status button {
  font-size: 1rem;
  padding: 8px 10px;
}
.btns-status button:first-child {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.btns-status button:last-child {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.section-evolucao {
  display: flex;
  align-items: center;
  gap: 10px;
}

.section-evolucao .arrow-section {
  font-size: 15px;
}

.item-regra-header {
  background-color: #f2f3f8 !important;
}

.automacao-edicao .dropdown-toggle::after {
  display: none;
}

.automacao-info, 
.automacao-toggle-info{
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.automacao-info label, 
.automacao-toggle-info label{
  font-weight: 700;
  color: var(--dommus-color-cinza);
  font-size: 0.8rem;
  margin-bottom: 0;
}

.automacao-toggle-info span{
  font-size: 0.9rem;
}

.etiqueta-badge{
    background-color: #f1a042;
    color: white;
    font-size: 0.8rem;
    padding: 3px 5px;
    border-radius: 3px;
    margin-left: 4px;
    font-weight: 500;
    overflow: hidden;
    cursor: default;    
    white-space: nowrap;
}

.automacao-toggle-info .periodo-section{
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.item-view-horarios{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.item-view-horarios .items-grade-horario-view{
  display: flex;
  flex-direction: row;
  gap: 5px;
}