.empreendimentos_pagina  
    .container {
    position: relative;
    width: 100%;
    max-width: 500px;
    }

    .img_profile {
        display: block;
        width: 100%;
        height: 15rem;
        object-fit: cover;
    }
    .icon_area{  
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 43px;
    }
    .text_icons{
        font: icon;
        font-size: 10px;
        text-align: center;
    }
    .tile_empreeendimento{
        margin-bottom: auto;
        position: relative;
        background:#183b64;
        width: 100%;
        color: white;
        font-size: 20px;
        padding: 20px;
        text-align: center;
        height: 80px;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    .deckEmpreendimento{
        padding-block-end: 17px;
        max-height: 547px !important;
    }
    .card-body {
        min-height: 100px !important;
        max-height: 130px !important; 
    }
    .cardEmpreeendimento{
        background-color: white;
        position:relative;
        cursor: default;
        box-shadow:0 2px 2px #999;
    }
    .cardEmpreeendimento:hover{
        top:-5px;
        box-shadow:0 4px 4px #999;
        transition: all .2s ease-in-out;
    }
    .btnCarrousel{
        text-decoration: none;
        outline: none;
        background-color: white;
        color: #999;
        border: unset !important;
        padding: 1px 1px;
    }
    
    .btnCarrousel:focus{
        box-shadow: none;
        
    }
    
.divStyleListEmp::-webkit-scrollbar {
    width: 0px;
}