
.agenda-event-actions .access-button{
    cursor: pointer;
    font-size: 10px;
    border: 1px solid #eb6613;
    width: 100%;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    color: #eb6613;
    margin-bottom: 5px;
}

