.areaPromocoes{
        padding-top: 15px;
        cursor: auto;
    }
.divlinkbanner{
    position:absolute;
    margin-top: 1%;
    left: 4%;
    background-color: var(--dommus-color);
    color: #f1f1f1;
}