.filtro-oferta-section{
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 10px;
}

.filtro-oferta-section button{
    border:none;
    background-color: transparent;
    cursor: pointer;
}