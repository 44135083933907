

.notificacoes-dropdown  .picture-topo{
    margin-right: 0px !important;
}

.dommus-menu .notificacoes-dropdown {
    background: none;
    display: flex;
    border: none;
}
.dommus-menu .notificacoes-dropdown h1.user-name {
    margin-top: 5px;
}
.dommus-menu .notificacoes-dropdown::after {
    content: none;
}
.notificacoes-dropdown + div{
    width: 360px;
}
.estilizacaoNotificacoes{
    padding: 5px 15px 10px  10px;
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
}
.estilizacaoNotificacoes span{
    color: #868aa8;
}


.listaNotificacoes{
    max-height: 30rem;
    overflow: hidden;
    overflow-y: auto;
}

.statusNotificacao{
    padding-top: 1.9rem;
    margin-left: 1.5rem;
    width: 25px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.statusNotificacao span{
    font-size: 40px;
    color: #ddd;
    display: flex;
    border-radius: 100%;
}

.notificacoes-dropdown + div .dropdown-item{
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
}
.listaNotificacoes .dropdown-item{
    display: flex;
    justify-content: space-between;
    padding: 5px 5px 5px 0 !important
}
.dataItemNotificacao{
    display: flex;
    text-align: right;
}
.circuloNotificacaoPendente{
    color: #183b64 !important;
}
.notificacoes-dropdown + div .dropdown-item.active, .notificacoes-dropdown + div .dropdown-item:active{
    background-color: #fff;
}

.itemNotificacao{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    overflow: hidden;
}
.textoItemNotificaco{
    white-space: break-spaces;
}
.itemNotificacao div{
    font-size: 13px;
    color: #666;
    line-height: 1.2;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.itemNotificacao span{
    font-size: 12px;
    color: #666;
}
.conteudoItemNotificacao{
    display: flex !important;
    text-align: left;
    flex-direction: row !important;
    justify-content: space-between !important;
    overflow-wrap: break-word;
    word-wrap: break-word;
    float:left; 
    width:200px; 
    white-space: pre-wrap;
    margin-left: 0.8rem;
    padding-top: 0.5rem ;
}

.dataItemNotificacao{
    display: flex;
    text-align: right;
    top: 0;
    padding-right: 7rem;
    padding-top: 0.5rem ;
}

.close-notificacao{
    color: #000;
}
.notificacoes-dropdown.btn-primary:not(:disabled):not(.disabled).active,
.notificacoes-dropdown.btn-primary:not(:disabled):not(.disabled):active,
.notificacoes-dropdown.show>.btn-primary.dropdown-toggle,
.notificacoes-dropdown.show>.btn-primary.dropdown-toggle:focus,
.notificacoes-dropdown.btn-primary.focus,
.notificacoes-dropdown.btn-primary:focus{
    background: none !important;
    border: none !important;
    box-shadow: none !important;
}
.notificationIcon {
    position: absolute;
    top: 0;
    right: 13px;
    font-size: 10px;
    width: 17px;
    height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F44336;
    border-radius: 50%;
    color: #fff;
}
.notificationsAction{
    width: 25%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.notificationsAction svg{
    cursor: pointer;
}

#row-notificacao {
    height: auto;
    max-height: 4.5rem;
    border-left: 5px solid rgb(84, 219, 84);
}

#dropdown-menu-notificacoes {
    width: 29rem;
    box-shadow: -3px 3px 20px #4f5050;
}

.titulo-notificacoes{
    padding-top: 0.5rem;
    height: 2rem;
    
}

#caixa-notificacao {
    border: solid green;
}

@media (max-width: 767px){
    .btn-notificacao{
        margin-right: 0px !important;
    }
}
