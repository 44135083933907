.tabela-dommus {
    border-collapse: collapse;
    width: 100%;
    /* table-layout: fixed; */
    overflow-x: auto;
}

.tabela-dommus thead {
    font-size: 14px;
    width: 100%;
}

.tabela-dommus th {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tabela-dommus td {
    max-lines: 2;
    text-overflow: ellipsis;
}

.body-tabela-relatorio {
    max-height: 60vh;
    width: 100%;
}

.header-tabela-relatorio {
    width: 100%;
}

.header-tabela-relatorio {
    position: sticky;
    /* Fixa o cabeçalho */
    top: 0;
    background-color: #f8f9fa;
    /* Define a cor de fundo do cabeçalho */
    z-index: 1;
    /* Garante que o cabeçalho fique acima do corpo ao rolar */
}

.card.card-table {
    display: block;
    border: 0;
    /* overflow: auto; */
    margin-bottom: 0;

}

.card.card-table.min-height-350 .table-responsive {
    min-height: 380px;
}

.card-table .table thead {
    background-color: #333;
    color: #fff;
}

.card-table .table tbody {
    background-color: #fff;
    min-height: 450px;
}

.card-table .table thead th {
    border-bottom-width: 1px;
    border-color: #453d3d;
}

.card-table .table tbody td,
.card-table .table tbody th {
    font-size: 12px;
    vertical-align: middle;
}

.tabela-paginada-div {
    display: block;
    overflow-y: auto;
    max-height: 60vh;
}

.tabela-paginada-div thead {
    width: max-content;
}

.tabela-paginada-div tfoot {
    display: table;
    width: 100%;
}
