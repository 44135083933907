.item-table{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.distribuir-row-section{
    display: flex;
    flex-direction: column;
}

.arrow-section{
    font-size: 2rem;
}

.linha{
    font-size: 14px;
}

.linha-menos-destaque{
    color: #999;
    text-align: start;
}

.linha-link{
    color: #fe9500;
    font-size: .9rem;    
}

.badge-nao-encontrado{
    margin-bottom: 10px;
    background: var(--dommus-color-red);
    border-radius: 8px;
    padding: 5px 15px;
    font-size: 14px;
    color: white;
    font-weight: 700;
}

.btn-contato-notificacao svg{
    font-size: 20px;
}

.btn-contato-notificacao:hover{
    background: var(--dommus-color-red) !important;

}

.btn-contato-notificacao:hover svg{
    color: white;
}

.btn-contato-notificacao svg{
    color: var(--dommus-color-red);
}

.btn-importar-leads svg{
    font-size: 20px;
}

.btn-importar-leads:hover{
    background: var(--dommus-color) !important;

}

.btn-importar-leads:hover svg{
    color: white;
}

.btn-importar-leads svg{
    color: var(--dommus-color);
}

.log-numero{
    color: #666;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
}

.nao-gerou-lead-section{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.reenviar-lead{
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 700;
    border-radius: 10px;
}