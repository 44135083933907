h2{
    font-size: unset;
    margin: 0;
}
.formCadastro{

}

.formCadastro label,
.formCadastro legend{
    color: #868aa8 !important;
    font-size: 12px;
    font-weight: 600 !important;
    margin-bottom: 0;
}

.formCadastro .form-control:focus{
    box-shadow: 0 2px 0 0 #183b64;
    border-color: #183b64;
}

/*.formCadastro .row {*/
/*    border-bottom: 1px solid #cccc;*/
/*}*/

.formCadastro .form-group{
    padding-top: 0;
    margin-bottom: 1rem;
}
.formCadastro .lastRow{
    border-bottom: 0;
}
.formCadastro .formFooter{
    border-top: 1px solid #cccc;
    border-bottom: 0;
    padding-top: 10px;
}
.formCadastro .formFooter .col{
    justify-content: flex-end;
    align-items: center;
    display: flex;
}

