


#container-box{
    display: grid;
    grid-template-columns: auto auto;
    gap: 10px;
    width: 100%;
   
   
}

@keyframes slideIn{
    0%{
        transform:translateX(-100%);
        opacity: 0;
    }

    100%{
        transform: translateX(0);
        opacity: 1;
    }
}


.box{
    border: 1px solid #DDD;
    border-radius: 15px;
    background-color: #FFF;
    padding: 10px;
    animation: slideIn 1s ease-out  forwards;
}

.thead-bg{
    background-color: #343A40;
}

.name-user-empreendimento{
    font-size: 13px;
}

.equal-columns {
    display: table;
    table-layout: fixed;
    width: 100%;
  }
  
  .equal-columns td {
    width: 20%;
  }
  
  .equal-columns .larger-column {
    width: 70%;
  }
.td-table{
    text-align: center;
    color: #FFF;
}

.onlineStatus-and-name{
    display:flex ;
    flex-direction: row;
    justify-content: flex-start;
  
}

.name-and-last-access{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    margin-left: 10px;
}

.last-access{
    font-size: 10px;
}

.empreendiemnto-title{
    font-size: 1.10rem;
    margin-left: 7px;
}


.empreendimento-plus-icon{
    display: flex;
    flex-direction: row;
    
}


.building-icon{
    font-size: 20px;
}

.td-body{
   
    text-align: center;
    font-size: 12px;
    
}

