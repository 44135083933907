.tag-section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* margin-top: 0.2rem; */
}

.tag-nome {
    background-color: #f1a042;
    color: white;
    font-size: 11px;
    padding: 3px 5px;
    border-radius: 3px;
    margin-left: 4px;
    font-weight: 500;
    overflow: hidden;
    cursor: default;    
    white-space: nowrap;
}

.use-ellipse{
    text-overflow: ellipsis;    
    max-width: 80px;
}

.tag-agrupada {
    background-color: #f1a042;
    color: white;
    font-size: 11px;
    padding: 3px 5px;
    border-radius: 3px;
    width: 2rem;
    margin-left: 4px;
    font-weight: 500;
    cursor: default;
}

