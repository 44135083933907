section.atalhos {
    z-index: 25;
    width: 25%;
    position: fixed;
    right: 0;
    top: 75px;
    min-width: 20%;
    height: calc(100% - 75px);
    background-color: #fff;
    transform: translateX(100%);
    z-index: 12;
    transition: var(--smooth);
    padding: 10px;
    overflow: auto;
}
section.atalhos .Primeiro{
    background-color: blue;
}

section.atalhos.open {
    transform: translateX(0);
    border-left: 1px solid #f2f3f8;
    overflow-x: hidden;
}

section.atalhos header {
    padding: 15px;
    border-bottom: 1px solid #f2f3f8;
    font-weight: 700;
    font-size: 14px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

section.atalhos header .close-pane {
    height: 100%;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    width: 55px;
    justify-content: center;
}

.sidenav-overlay.open {
    display: block !important;
    opacity: 1;
}
.sidenav-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
    height: 120vh;
    background-color: rgba(0,0,0,.5);
    z-index: 7;
    display: none !important;
    transition: var(--smooth);
}

section.atalhos .card-text {
    font-size: 12px;
    font-weight: bolder;
    width: 100%!important;
}

section.atalhos .card {
    text-align: center;
    color: white;
}

section.atalhos .direita.col {
    padding-left: 0px;
}

section.atalhos .totalLeads, .leadsHoje {
    background-color: #f7941d!important;
}

section.atalhos .semAtendimento, .slaAtendimento{
    background-color: #183b64!important;

}
section.atalhos .totalTransferencias, .transferenciasDia {
    background-color:#f7941d!important;
}

section.atalhos .totalAgendamentos, .agendamentosDia{
    background-color: #183b64!important;

}
section.atalhos .leadsDescartados, .descartadosDia{
    background-color: #f7941d!important;

}
section.atalhos .totalTarefas {
    background-color: #183b64!important;

}
section.atalhos .totalTarefasAtrasadas {
    background-color: #C82333;

}
section.atalhos .accordionTarefas {
    color: black;
    
}

.linha {
    flex-wrap: nowrap!important;
}

.body-card {
    max-height: 120px!important;
}