[data-rbd-droppable-id="regua-nutricao-dnd"] > .MuiGrid-root {
    height: inherit!important;
    overflow-x: auto!important;
}
.conteudo-arrastar {
    margin: 0px!important;
}
[data-rbd-droppable-id="regua-nutricao-dnd"] {
    width: 100%!important;
}
.icone-meio-comunicacao {
    position: absolute;
    padding: 7px 10px;
    border-radius: 100%;
}
.prazo {
    font-size: 0.75rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
}