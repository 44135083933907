.publico-section .dommus-toggle-icones{
    width: 100%;
}

.icons-section{
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}
.icons-section div{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.icons-section .btn-questionario-remover{
    color: var(--dommus-color-red);
    padding: 2px 4px;
}

.btn-link{
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    font-size: 0.8rem;
    color: white;
    background-color: var(--dommus-color);
    padding: 2px 10px;
}

.btn-link:hover{
    color: white;
    text-decoration: none;
}

.badge-resposta{
    border-radius: 5px;
    padding: 3px 10px;
    color: white;
    font-size: 0.9rem;
}

.badge-resposta.badge-respondido{
    background-color: var(--dommus-color-green);
}

.badge-resposta.badge-aberto{
    background-color: var(--dommus-color-2);
}

.badge-resposta.badge-expirado{
    background-color: var(--dommus-color-red);
}

.resposta-container{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.resposta-container .info-section{
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 10px;
    border-radius: 5px;
    background-color: rgb(236, 236, 236);
}
.resposta-container .info-section .dados{
    display: flex;
    flex-direction: column;    
}
.resposta-container .info-section .dados label{
    font-size: 0.8rem;
    color: grey;
    margin-bottom: 0px;
}

.resposta-container .info-section .dados span{
    text-transform: capitalize;
}