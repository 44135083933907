fieldset {
    border: 1px solid #ddd;
    padding: 4px;
}
legend {
    width: auto;
    font-size: 1.1em;
}

.divEdit{
   display: grid;
   grid-template-columns: 20fr 0fr;
}
.dataTipEdit{
    margin-left: 10px;
    font-size: 16px;
}

.psize{
    font-size: 14px;
}

.pointerInativo{
    color: #e1d7d7;
    cursor: no-drop;
}