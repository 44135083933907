.form-automacao{
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 250px);
    background-color: #f5f5f5;
}

.title-toggle-automacao-form{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.title-toggle-automacao-form svg{
    margin-right: 10px;
    font-size: 1.2rem;
    color: var(--dommus-color);
}