.form-perguntas-opv{
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.atualizacao-section{
    color: #888;
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-right: 10px;
    font-weight: 600;
}

.atualizacao-section-mobile{
    display: flex;
    color: #888;
    flex-direction: column;
    gap: 5px;
    font-size: 0.9rem;
    font-weight: 600;
}

.btn-gerar-link{
    background-color: var(--dommus-color);
    color: white;
    padding: 1px 8px;
    font-size: 0.8rem;
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

}

.btn-gerar-link:hover{
    background-color: var(--dommus-color-2);
    color: white;
}

.mensagem-section{
    padding: 10px 5px;
    color: grey;
    font-weight: 600;
}

.publico-section{
    padding: 15px 0px;
}

@media (max-width: 767px){
    .btn-gerar-link{
        display: flex !important;
    }
}