.qualificacao-header{
    background-color: rgb(246, 246, 246);
    margin: 0px 0px;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
}

.qualificacao-header span{
    font-weight: 600;
}

.btns-qualificao-section{
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-end;
}

.btn-qualificao{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.qualificacao-usuario-container{
    overflow-y: hidden;
    overflow-x: hidden;
    padding: 0px 10px;
}

.footer-qualificacao-usuario{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    padding: 10px;
    padding-top: 15px;
    border-top: 1px solid gainsboro;
}

.equipe-qualificacao-section{
    max-height: 66vh;
    overflow-y: auto;
}

.campanha-qualificacao-section{
    display: flex;
    flex-direction: column;
    gap: 10px;
}   

.campanha-qualificacao-item{
    display: flex;
    flex-direction: column;
    gap: 10px;
    border: 1px solid gainsboro;
    border-radius: 8px;
}

.campanha-qualificacao-item .campanha-title{
    background-color: gainsboro;
    border-radius: 5px;
    padding: 5px 8px;
    font-weight: 600;
}

.campanhas-qualificacao-section{
    display: flex;
    flex-direction: column;
    padding: 8px;
    gap: 10px;
}

.campanhas-qualificacao-section span{
    border: 1px solid gainsboro;
    padding: 5px;
    border-radius: 5px;

}


@media (max-width:767px) {
    .qualificacao-header{
        justify-content: center;
    }
}