.automacao-regras-section .dommus-toggle{
    border-left: 5px solid rgb(28, 70, 169) !important;
}

.btn-add-regra{
    justify-self: center;
    padding: 10px 15px;
    margin-right: 20px;
    background: white;
    border-radius: 5px;
    border: 1px solid #ddd;    
    color: #4caf50;
}

.btn-add-regra:hover{        
    background: #4caf50;
    border: 1px solid #4caf50;
    color: white;
}

.automacoes-blocos{
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

.condicionantes-section{
    display: flex;
    flex-direction: column;
    gap: 10px;
}