.perfil_empreendimento_pagina
    .filtro{
        position:relative;
    }
    .topoCont{
        padding: 20px;
        /* background-image: url('../../assets/bh.jpg'); */
        background-position: center;
        width: auto;
        height: 300px;
        top: initial;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: center;
        border-radius: 2px;
        
    }
    .topoCont:after{
        content:'';
        position:absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        background-color:rgba(35, 91, 155, 0.541); 
    }
    .topoCont title{
        font-size: 30px;
        display: flex;
        color: ivory;
        z-index: 2;
        text-shadow: black 0.1em 0.1em 0.2em;
        margin-bottom: 50px;
    }
    .profile{
        border: 3px solid #ffffff;
        display: flex;
        width: 10em;
        height: 10em;
        z-index: 2;
    }
    .navegation{
        width: 100%;
        display: flex;
        align-items: center;
        align-content: center;
        font-weight: 700;
        flex-direction: column;
        justify-content: end;
        font-size: 14px;
        margin-top: -41px;
        
    }
    .navegation .tab-content{
        width: 100%;
    }
    .navegation .nav{
        z-index: 1;
    }
    .navegation .nav a{
        color: #ffffff ;
    }
    .navegation .nav-link.active{
        border-bottom: 3px solid #f7941d;
    }
    .navegation .nav-link.disable{
        color:#f7941d;
    }

    .button-voltar-empreendimentos{
        cursor: pointer;
        background-color: #ffffff;
        margin-bottom: 15px;
        padding: 10px;
        border-radius: var(--dommus-b-radius); 
        border: 1px solid #ddd;
    }

    .change-file {
        /*  */
        display: flex;
        /* width: 100%;
        height: 100%; */
        position: relative;
        color: #fff;
        text-align: center;
        cursor: pointer;
        /* background-color: var(--dommus-color-cinza); */
    }

    .change-file:hover .btn-file{
        opacity: 1;
    }

    .picturePortalCorretor {
        background: url(http://homologacao2.dommus.com.br/nova-ui2/assets/img/bh.jpg)no-repeat center center;
        background-size: cover;
        margin-bottom: 15px;
        position: relative;
        width: 120px;
        height: 120px;
        border-radius: 50%;
        display: inline-block;
    }

    .btn-file{
        position: absolute;
        font-weight: bold !important;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: .5s ease;
        margin-top: 50px;
        font-size: medium;
    }

    .divimgfundo{
        position: absolute;
        margin-left: 95%;
        z-index: 1;
    }

    .change-file:hover .image-profile{
        opacity: 0.5;
    }

    .image-profile{
        border-radius: 50%;
        display: block;
        width: 100%;
        height: auto;
    }

    .file_upload_profile{
        opacity: 0.0;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height:100%;
    }

    .file_upload_profile:hover{
        cursor: pointer;
    }

    .anexoFotosIcon{
        display: inline-block;
    }

    .anexoFotosIcon:hover{
        cursor: pointer;
    }