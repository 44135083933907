.cardDash{
  height: 130px;
  width: 25%;
  margin-left: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 5px;
  justify-content: space-between;
}

.dashboard{
  display: flex;  
  padding: 10px;
  background-color: #fff;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.tituloCard{
  color: #fff;
  font-weight: bold;
}

.qtdCard{
  color: #fff;
  font-weight: 500;
  font-size: 40px;
  align-self: center;
}

.footerCard{
  color: #fff;
  font-size: 13px;
  font-weight: 500;
}

.label-calendar{
  color: #666;
  font-weight: 600;
  font-size: 1.1rem;
  align-self: center;
}

@media (max-width: 767px){
  .dashboard{
    flex-wrap: wrap;    
    gap: 10px;
  }

  .cardDash{
    width: 100%;
    margin-left: 0px;
  }
}
