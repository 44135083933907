
.list-group-relatorios-diversos{
    min-height: 12rem;
    max-height: 12rem;
    overflow: auto;
}

.list-item-relatorios-diversos{
  padding-bottom: 0.5rem;
  border: solid lig;
 }

.list-item-relatorios-diversos:hover{
 box-shadow: 0 0 5px lightgrey;
 background-color: #183b64;
 color: white;
 cursor: pointer;
}

.list-item-relatorios-diversos:active{
    background-color: lightgray;
}

.container-relatorios-diversos{
    min-height: 80vh;
}

.col-generic-margin{
 margin-bottom: 1rem;
}

.col-button-pesquisar{
 display: flex;
 justify-content: end;
}

.tabela-resultado-relatorio{
    overflow-x: scroll;
}

.tabela-resultado-relatorio .tabela-dommus td{
  padding: 15px 20px;
  text-wrap-mode: nowrap;
}
