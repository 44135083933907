
.resposta-item .enunciado-section span{
    color: gray;
}
.resposta-item .resposta-section{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    font-weight: 600;
}

.resposta-item .resposta-section label{
    margin-bottom: 0px;
    font-size: 0.8rem;
    color: gray;
    font-weight: 500;
}