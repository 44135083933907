.eventos-regua-nutricao .vertical-timeline {
  height: 20vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: auto;
  overflow-y: hidden;
  gap: 15px;
}

.eventos-regua-nutricao .vertical-timeline .vertical-timeline-element:first-child {
  margin: 2em 0;
}

.eventos-regua-nutricao {
  margin-bottom: 20px;
}