.empreendimento-qualificacao-container{
    max-height: 66vh;
    overflow-y: auto;
}

.empreendimento-qualificacao-section{
    display: flex;
    flex-direction: column;
    gap: 10px;
}   

.empreendimento-qualificacao-item{
    display: flex;
    flex-direction: column;
    gap: 10px;
    border: 1px solid gainsboro;
    border-radius: 8px;
}

.empreendimento-qualificacao-item .empreendimento-title{
    background-color: gainsboro;
    border-radius: 5px;
    padding: 5px 8px;
    font-weight: 600;
}

.gerentes-qualificacao-section{
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 3px 18px;
}

.corretor-switch-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding: 4px;
    padding-bottom: 8px;
}

.indicador-gerente{
    width: 20px;
    height: 20px;
    background-color: gray;
    border-radius: 50%;
}
.indicador-gerente.ativo{
    background-color: var(--dommus-color-green);
}

.corretor-switch-section .form-switch, .corretor-switch-section .form-switch label{
    padding: 0px !important;
    margin-bottom: 0px !important;
}