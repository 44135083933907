.status-on{
    width:18px;
    border: 1px solid #4DAF50;
    color: #4DAF50;
    border-radius: 15px;
}


.status-off{
    width: 18px;
    border: 1px solid #F44336;
    color: #F44336;
    border-radius: 15px;
}