.dommus-menu{
    position: fixed;
    width: 100%;
    z-index: 8;
    height: 75px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    background-color: #183b64;
    color: #fff;
}

.dommus-menu .left-nav{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    width: 100%;
}

.dommus-menu .left-nav .logo{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.dommus-menu .left-nav .logo picture {
    display: flex;
    align-items: center;
    height: 100%;
}

.dommus-menu .left-nav .logo img{
    width: 8rem;
    max-width: fit-content;
}

.dommus-menu .left-nav .logo .modulo-name {
    display: flex;
    gap: 5px;
    align-items: center;
    font-weight: 700;
    color: #fff;
    font-size: 1.1rem;
    line-height: unset;
    text-transform: uppercase;
}

.dommus-menu .online{
    margin-right: 20px;
}

.dommus-menu .right-nav {
    display: flex;
    height: 75px;
}

.dommus-menu .info-nav{
    font-size: 30px;
    height: 75px;
    align-items: center;
    display: inline-flex;
}
.dommus-menu .info-nav .picture-topo img{
    width: 25px;    
}
.dommus-menu .info-nav .barsMenuMobile{
  display: none;
}

.dommus-menu .info-nav  .picture-topo{
    cursor: pointer;
    width: 30px;
    margin-right: 20px;
    position: relative;
    display: flex;
    align-items: center;
}

.dommus-menu .right-nav .info-nav > div {
    margin-right: 20px;
    display: inline-flex;
}

.input-pesquisa {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-top: 1rem;
}

.dommus-menu .right-nav .user-name {
    color: #fff;
    font-size: 15px;
    line-height: unset;
    padding: 0;
    margin: 0px 5px;

}
.dommus-menu .online{
    display: flex;
    align-items: center;
}
.dommus-mobile-navigation{
    display: none;
}

.iconeCockpit{
    margin-right: 30px !important;
}

@media (max-width:767px) {
    .dommus-menu .info-nav  .picture-topo{
        margin-right: 13px;
    }
    .dommus-menu .right-nav{
        margin-left: 5px;
    }
    .dommus-menu{
        height: 55px;
    }
    .dommus-menu .left-nav .input-pesquisa{
        display: none;
    }

    .dommus-menu .left-nav .logo img{
        width: 100%;
        max-width: fit-content;
    }
    .dommus-menu .right-nav .info-nav .online{
        display: none;
    }
    .dommus-menu .right-nav .info-nav .central-ajuda{
        display: none;
    }
    .dommus-menu .onlineFlag{
      display: none;
    }
    .dommus-menu .info-nav  .picture-topo .onlineFlag{
        display: none;
    }
    .dommus-menu .info-nav  .picture-topo .offlineFlag{
        display: none;
    }
    .dommus-mobile-navigation{
        display: block;
    }
    .dommus-menu .info-nav .barsMenuMobile{
      display: flex;
      width: 26px;
      margin-left: 20px;
    }
    .dommus-menu .info-nav .picture-topo{
        margin-right: 2px !important;
    }
}
.dommus-menu .perfil-dropdown {
    background: none;
    display: flex;
    border: none;
}
.dommus-menu .perfil-dropdown h1.user-name {
    margin-top: 5px;
}
.dommus-menu .perfil-dropdown::after {
    content: none;
}

.perfil-dropdown.btn-primary:not(:disabled):not(.disabled).active,
.perfil-dropdown.btn-primary:not(:disabled):not(.disabled):active,
.perfil-dropdown.show>.btn-primary.dropdown-toggle,
.perfil-dropdown.show>.btn-primary.dropdown-toggle:focus,
.perfil-dropdown.btn-primary.focus,
.perfil-dropdown.btn-primary:focus{
    background: none !important;
    border: none !important;
    box-shadow: none !important;
}
.boxSair{
    width: 250px;
    padding: 2px;
}
.boxSair ul{
    margin-bottom: 0px;
}

.liFlexSair{
    display: flex;
    justify-content: space-between;
    align-items: center; 
    margin: 0px -10px 0px -10px;
}

.input-pesquisa {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-top: 1rem;
    width: 100%;
}