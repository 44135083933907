.mapeamentos-dinamicos-section{
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 5px;
  border-top: 1px solid gainsboro;
  padding: 5px;
  padding-top: 10px;
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.bloco-mapeamento-section{
  display: flex;
  flex-direction: row;
  background-color: #f8f8f8;
  gap: 10px;
  border: 1px solid gainsboro;
  border-radius: 10px;
  padding: 10px 0px;
}

.bloco-mapeamento-section .form-group{
  margin-bottom: 0px;
}

.bloco-mapeamento-section .btn-remover-bloco{
  height: min-content;
  align-self: flex-end;
}


.footer-mapeamento{
  border-top: 1px solid gainsboro;
  padding-top: 10px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
