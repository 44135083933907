.linha{
    display: flex;
    justify-content: space-between;
}

.linha .codigo{
    color: #f44336;
}

.linha h1{
    color: #333333;
    font-size: 13px;
    font-weight: bold;
    padding: 5px 0;


}

.linha .texto{
    font-size: 12px;
    letter-spacing: normal;
    font-weight: normal;
    padding: 5px 0;
    color: #333333;
}

.card{
    width: 100%;
    background-color: unset;
    margin-top: 10px;
    border-radius: 5px;
    cursor: pointer;
    border: 0;
}
.card header.dommus-toggle + div{
    padding: 0;
}

.cardTopo{
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    align-items: center;
    min-height: 95px;
    padding-left: 0;
}

.cardTopoAtendimento{
    height: unset
}
.cardBaixo{
    background-color: #fff9db;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.cardBaixo .data{
    font-size: 12px;
}
.cardBaixo .data svg{
    margin-right: 10px;
}
.icones{
    display: flex;
    justify-content: space-between;
    width: 10px;
}

.conteudoItem{
    width: 100%;
    padding: 10px;
}
.card button.ellipsis-toggle {
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: unset !important;
    line-height: 1;
    width: 10px;
    padding: 0;
    margin: 0;
    border: 0;
    display: flex;
    justify-content: center;
}

.cardTopoAtendimento .form-switch{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}
.cardTopoAtendimento .form-switch span{
    font-size: 0.7rem;
    color: var(--dommus-color-cinza);
}
