@media (max-width:425px) {
    .search-wrapper .search-content .search-title{
        display: flex!important;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
    }
    .search-wrapper .search-content .search-title h3{
        padding: 0 15px;
        height: 50px;
        display: flex;
        align-items: center;
    }
    .search-wrapper .search-content .search-title .right-nav{
        justify-content: space-between;
        flex-direction: column;
    }
    .search-wrapper .search-content .search-title ul li:first-child{
        margin-left: 0;
    }
    .search-wrapper .search-content .search-title .right-nav .close-search{
        position: absolute;
        top: 12.5px;
        right: 15px;        
    }
   
    .search-wrapper .search-content .search-title .item-ativos{
        padding: 15px;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        width: 100%;
        justify-content: flex-start;
    }
    .search-wrapper .search-content .search-title .item-ativos .custom-control:first-child{
        margin-right: 15px;
    }
    .search-wrapper .search-content .search-title ul{
        align-items: center;
        justify-content: space-around;
        width: 100%;
        border: 0;
        padding: 15px;
        margin: 0;
        border-top: 1px solid #ddd;
    }
    .filter-wrapper{
        padding: 0;
    }
    .search-wrapper{
        top: 3.5rem !important;
    }
}

@media (max-width: 767px){
    .search-wrapper{
        top: 3.5rem !important;
    }
}

.search-wrapper{
    position: fixed;
    width: 100%;
    left: 0;
    top: 4.5rem;
    max-height: 93%;    
    align-items: start;
    justify-content: center;
    background-color: rgba(0,0,0,.5);
    z-index: 99;
    overflow: auto;
    font-family: 'Poppins', Arial, Helvetica, sans-serif !important;
    font-size: 14px;
}
.search-wrapper .search-content{
    width: 100%;
    max-width: 100%;
    display: flex;
    padding-bottom: 30px;
    align-items: flex-start;
    /*justify-content: center;*/
    flex-direction: column;
    background-color: #fff;
    min-height: 15%;
}
.search-wrapper .search-content .search-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-transform: uppercase;
    padding: 0px 15px;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
}
.search-wrapper .search-content .search-title h3{
    font-weight: 700;
    color: #666;
    font-size: 15px;
}

.search-wrapper .search-content .search-title .item-ativos{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.search-wrapper .search-content .search-title .filter-right-nav{
    display: flex;
    align-items: center;
}
.search-wrapper .search-content .search-title .close-search{
    background-color: transparent;
    border: none;
    color: #666;
    font-size: 1.2rem;
    padding: 5px;
}
.input-pesquisa-control{
    position: relative;
    background-color: #183b64;
    color: #FFF;
}
.input-pesquisa-control:focus{
    background-color: #183b64;
    color: #FFF;
}

.clean-input{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    top: 7px;
    right: 15px;
    color: white;
    border: none;
    background-color: transparent;
}