.link-avulso-section{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.link-avulso-gerado{
    border: 1px solid gainsboro;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

.link-section{
    display: flex;
    flex-direction: row;
    align-items: center;    
    border: 1px solid gainsboro;
    border-radius:8px;
    padding: 10px;
    width: 100%;
}

.link-section span{
    width: 95%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    
}

.link-section .icon-copy{
    cursor: pointer;
    color: grey;
}

.qr-code-section{
    position: relative;
    width: 13rem;
    align-self: center;
}

.qr-code-section .exportar-overflow{
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(141, 153, 174, 0.8);
    color: white;
    font-size: 2.5rem;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.qr-code-section:hover .exportar-overflow{
    display: flex;
}

.btn-tipo-link.link-avulso{
    width: 100%;
    background-color: var(--dommus-color);
    color: white;
}
.btn-tipo-link.link-avulso:hover{
    color: white;
}
