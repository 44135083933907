.btn-atendimento-section{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.btn-fila-atendimento{
    border: 1px solid gainsboro;
    padding: 2px 8px;
    border-radius: 5px;
    cursor: pointer;
}

.usuario-nao-qualificado {
    color: #939698!important
}