.btn-add-automacao{
    justify-self: center;
    padding: 10px 15px;
    margin-right: 20px;
    background: white;
    border-radius: 5px;
    border: 1px solid #ddd;    
    color: #4caf50;
}

.btn-add-automacao:hover{        
    background: #4caf50;
    border: 1px solid #4caf50;
    color: white;
}

.automacao-section .dommus-toggle{
    border-left: 5px solid orange !important;
}