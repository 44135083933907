.btn:hover button svg path {
    fill: #fff;
}

.btn.filaDistribuicao:hover svg path{
    fill: #000000 !important;
}


.checkBox {
    display: flex;
    align-items: center;
    margin: 0 15px 0 10px !important;
}

.checkBoxTopo{
    margin: 0 20px 0 10px !important;
}

.btn .dropdown-item{
    padding: 8px 15px;
}

.btn-historico-distribuidos{
    background: #fe9500 !important;   
    border: 1px solid #fe9500; 
    cursor: pointer;
}

.btn-historico-distribuidos svg{
    color: white;
}

.btn-historico-distribuidos:hover{
    background: white !important;    
    color: #fe9500 !important; 
    border: 1px solid #fe9500; 
}

.btn-historico-distribuidos:hover svg{
    color: #fe9500 !important; 
}

.btn-distribuicao:hover{
    background: #4caf50;
}

.iconeTopo{
    color: #353252;
}

.btn-distribuicao:hover .iconeTopo{
    color: white;
}

.btn .dropdown-item svg{
 margin-right: 5px;
}