.switch-visita .form-switch-control{
  display: flex;
  align-items: center;
  padding-left: 0px !important;
}

.formFooter{
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: end;
}
