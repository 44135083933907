.btn-action-linha{
    cursor: pointer;
    border: none;
    background-color: transparent;
}

.btn-action-section{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
}

.btn-salvar-linha{
    color: var(--dommus-color-green);    
}

.btn-excluir-linha{
    color: var(--dommus-color-red);
}

.input-table{
    border: none;
    background-color: transparent;
    width: 100%;
    cursor: text;
    border-radius: 5px;
    padding: 0px 5px;    
    min-height: 30px;
}

.input-table:hover,.input-table:focus{
    background-color: gainsboro;
}

.react-select-visita-form:active{
    outline: none;
    
}

.react-select-visita-form .react-select__control{
    border: none;
    min-height: auto;
    background-color: transparent;    
}

.react-select-visita-form .react-select__control:hover{
    background-color: gainsboro;
}

.react-select-visita-form .react-select__control:active{
    outline: none;
    
}

.react-select-visita-form .react-select__control .react-select__value-container{
    padding: 0px 2px;
    font-size: 0.9rem;
}

.react-select-visita-form .react-select__control .react-select__indicators{
    width: fit-content;
}
.react-select-visita-form .react-select__control .react-select__indicators span{
    display: none;
}

.react-select-visita-form .react-select__control .react-select__indicators div{
    padding: 1px;
}

.react-select-service-order .react-select__menu .react-select__menu-list{
    max-height: 150px;
    font-size: 0.9rem; 
}