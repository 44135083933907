.historico-lead {}

.criadoTimeline {
    color: #999;
    font-size: 10px;
}

.historico-lead .tab-pane ul {
    min-height: 445px;
    padding-right: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: inherit;
    list-style-type: none;
    height: 100%;
    overflow: auto;
}

.historico-lead .tab-pane ul li {
    font-size: 12px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 8%;
}

.historico-lead .tab-pane ul li::before {
    content: "•";
    position: absolute;
    right: -27px;
    display: inline-block;
    font-size: 35px;
    color: #ddd;
    z-index: 2;
}

.historico-lead .tab-pane ul li.active::before {
    color: #f7941d;
}

.historico-lead .tab-pane ul li:not(:first-child)::after {
    content: "";
    position: absolute;
    top: -68px;
    right: -21px;
    width: 2px;
    height: 95px;
    background-color: #ddd;
    z-index: 1;
}

.historico-lead .tab-pane ul li,
.historico-lead .tab-pane ul li.active {
    margin: 20px 0;
    text-align: right;
    cursor: pointer;
    padding: 0px 0px 0px 10px;
}

.historico-lead .tab-pane ul li:hover,
.historico-lead .tab-pane ul li.active:hover {
    background-color: #f8f8f8;
}

.historico-lead .tab-pane ul li.active::after {
    background-color: #f7941d !important;
}

.mobileModalLead {
    display: none;
}

.top-group-radio {
    display: flex;
    justify-content: center;
    align-items: center;
}

.top-group-radio-historico {
    display: flex;
    justify-content: center;
    align-items: center;
}

.top-group-radio .mb-2 {
    margin-bottom: 0px !important;
}

.top-group-radio-historico .mb-2 {
    margin-bottom: 0px !important;
}

.top-group-radio .mb-2 .btn-group>.btn-group:not(:last-child)>.btn, .btn-group>.btn:not(:last-child):not(.dropdown-toggle) input[type=radio] {
    display: none;
}

.top-group-radio-historico .mb-2 .btn-group>.btn-group:not(:last-child)>.btn, .btn-group>.btn:not(:last-child):not(.dropdown-toggle) input[type=radio] {
    display: none;
}

.top-group-radio-historico .btn-group>.btn-group:not(:last-child)>.btn, .btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
    width: 14vw;
}

.btn-group>.btn-group:not(:last-child)>.btn, .btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
    width: 14vw;
}

.btn-group>.btn-group:not(:first-child)>.btn, .btn-group>.btn:not(:first-child) {
    width: 14vw;
}

.top-group-radio .mb-2 .btn-group>.btn-group:not(:first-child)>.btn, .btn-group>.btn:not(:first-child) input[type=radio] {
    display: none;
}

.top-group-radio-historico .mb-2 .btn-group>.btn-group:not(:first-child)>.btn, .btn-group>.btn:not(:first-child) input[type=radio] {
    display: none;
}

.top-group-radio {
    margin-bottom: 10px;
}

.top-group-radio-historico {
    margin-bottom: 10px;
}

.top-group-radio .btn-outline-warning {
    color: #aaa;
    border-color: #aaa;
}

.top-group-radio-historico .btn-outline-warning {
    color: #aaa;
    border-color: #aaa;
}

.top-group-radio .btn-outline-warning:hover {
    color: #fff;
    background-color: #aaa;
    border-color: #aaa;
}

.top-group-radio-historico .btn-outline-warning:hover {
    color: #fff;
    background-color: #aaa;
    border-color: #aaa;
}

.vertical-timeline--animate .vertical-timeline-element-content.bounce-in h5 {
    font-size: 15px;
}

.vertical-timeline-element-content .vertical-timeline-element-date {
    padding: 0 !important;
    align-self: flex-end;
}

.vertical-timeline-element-data{
    margin-bottom: 10px;
    font-size: 12px;
    color: rgb(243, 144, 23);
}

.vertical-timeline-element-body {
    width: 100%;
    margin: 10px 0px;
    color: #666;
    font-size: 12px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; 
            line-clamp: 4;
    -webkit-box-orient: vertical;    
    white-space: pre-line;
}

.vertical-timeline-element-subtitle {
    margin: 0px;
    line-height: 18px;
    margin-top: 8px;
    font-size: 12px;
}

.top-group-radio .btn-outline-warning:not(:disabled):not(.disabled).active, .btn-outline-warning:not(:disabled):not(.disabled):active, .show>.btn-outline-warning.dropdown-toggle {
    color: #fff;
    background-color: #666;
    border-color: #666;
    width: 14vw;
}

.top-group-radio-historico .btn-outline-warning:not(:disabled):not(.disabled).active, .btn-outline-warning:not(:disabled):not(.disabled):active, .show>.btn-outline-warning.dropdown-toggle {
    color: #fff;
    background-color: #666;
    border-color: #666;
    width: 14vw;
}

@media (max-width:767px) {
    .mobileModalLead {}

    .top-group-radio-historico .btn-outline-warning:not(:disabled):not(.disabled).active, .btn-outline-warning:not(:disabled):not(.disabled):active, .show>.btn-outline-warning.dropdown-toggle {
        width: 100%;
    }
}

.vertical-timeline {
    padding: 0 0 10px 0 !important;
    height: 50vh;
}

@media (min-width: 1170px) {
    .vertical-timeline-element {
        margin: 2em 0;
    }
}

.vertical-timeline--animate .vertical-timeline-element-icon.bounce-in svg {
    width: 24px !important;
}

.vertical-timeline-element:last-child {
    padding: 0px 0px 20px 0px;
}