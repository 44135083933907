.div-sobre-icone{
    display: flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
    flex-flow: row wrap;
    padding: 10px;
    top: 45px;
    left: 15px;
    width: 100%;
    height: 60vh;
    overflow: auto;
    overflow-x: hidden;
}
.btn-carregar-icones{
    cursor: pointer;
    margin-top: 30px;
    padding: 8px 35px;
}
.modal-exibe-icones{
    display: flex;
    flex-direction: column;
    align-items: center
}
.icone{
    width: 68px;
    height: 28px;
    cursor: pointer;
    position: relative;
    border-radius: 18px;
    margin: 5px 0px 5px 16px;
}