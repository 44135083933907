.anuncios-assinados-container{
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.anuncios-assinados-container .btn-adicionar-anuncio{
    background-color: var(--dommus-color);
    color: white;
}

.anuncios-assinados-container .anuncios-assinados-section{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.anuncios-assinados-container .anuncios-assinados-section .anuncio-section{
    display: flex;    
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid gainsboro;    
    border-radius: 10px;
    padding: 5px 10px;
}

.anuncios-assinados-container .anuncios-assinados-section .anuncio-section .anuncio-info{
    display: flex;
    flex-direction: column;    
    gap: 0px;
}

.anuncios-assinados-container .anuncios-assinados-section .anuncio-section .anuncio-info label{
    font-size: 0.8rem;
    color: gray;
    margin-bottom: 0px;
}
.anuncios-assinados-container .anuncios-assinados-section .anuncio-section .anuncio-info span{
    font-weight: 600;
}

.anuncios-assinados-container .anuncios-assinados-section .anuncio-section .btn-remover-anuncio{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: var(--dommus-color-red);
    padding: 5px 10px;
    color: white;
}

.form-anuncio-assinar{
    display: flex;
    flex-direction: column;    
    width: 100%;
}

.form-anuncio-assinar .btn-adicionar-anuncio{
    display: flex;
    align-self: flex-end;
    width: fit-content;
    background-color: var(--dommus-color-green);
    color: white;
}