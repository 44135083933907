h2{
    font-size: unset;
    margin: 0;
}
.formCadastro{

}

.formCadastro label{
    color: #868aa8 !important;
    font-size: 12px;
    font-weight: 400 !important;
    margin-bottom: 0;
}

.formCadastro .form-control:focus{
    box-shadow: 0 2px 0 0 #183b64;
    border-color: #183b64;
}

/*.formCadastro .row {*/
/*    border-bottom: 1px solid #cccc;*/
/*}*/

.formCadastro .form-group{
    padding-top: 0;
    margin-bottom: 1rem;
}
.formCadastro .lastRow{
    border-bottom: 0;
}
.formCadastro .formFooter{
    border-top: 1px solid #cccc;
    border-bottom: 0;
    padding-top: 10px;
    align-items: center;
}
.formCadastro .formFooter .col,
.formCadastro .formFooter .col-lg-6,
.formCadastro .formFooter .col-md-12{
    align-items: center;
    display: flex;
}
.mensagemConteudoReceived{
    display: flex;
    background-color: #e8ffd6;;
    padding: 10px;
    flex-direction: column;
    border-radius: 10px;
    margin-bottom: 10px;
    border-bottom-left-radius: 0px;
}
.mensagemConteudoSent{
    display: flex;
    background-color: #d6dcff;
    padding: 10px;
    flex-direction: column;
    border-radius: 10px;
    margin-bottom: 10px;
    border-bottom-right-radius: 0px;
}
.linhaMensagemReceived{
    display: flex;
}
.linhaMensagemSent{
    display: flex;
    justify-content: flex-end;
}

.listaMensagens span{
    font-size: 11px;
    text-align: right;
}
.listaMensagens{
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 400px;
    width: 100%;
    padding: 0px 20px;
}

@media (max-width:767px) {
    .formCadastro .formFooter .col button{
      width: 100%;  
    }
}