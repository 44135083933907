.modal-fullscreen-oportunidade {
    width: 99vw;
    height: calc(100vh - 75px);
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: white;
    display: none;
    transform: translateY(100vh);
    z-index: 2;
}

.modal-fullscreen-oportunidade.show {
    display: block;
    animation: show-modal 600ms ease-in forwards;
}

.modal-fullscreen-oportunidade .modal-header {
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: 600;
    color: var(--dommus-color-cinza);

}

.modal-fullscreen-oportunidade .modal-header .icon-close {
    cursor: pointer;
    color: var(--dommus-color-cinza);
}

.modal-fullscreen-oportunidade .modal-conteudo {
    display: block;
    padding: 10px;
    overflow-y: auto;
    height: calc(100vh - 125px);
}

@keyframes show-modal {
    0% {
        transform: translateY(100vh);
    }
    100% {        
        transform: translateY(0);
    }
}
