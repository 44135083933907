.MuiBadge{
  padding: 3px;
  border-radius: 5px;
  color: #fff;
  font-weight: 500;
}

.idOpv{
  color: #F05454;
}

.nomeLead{
  font-weight: 500;
}

.labelPadrao{
  font-size: 14px;
  color: #c2c2c2;
  margin-bottom: 2px;
}

.horaWrapper{
  display: flex;
  justify-content: center;
}

.labelForm{
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 2px;
}