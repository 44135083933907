.conteudoEtapaStatus {
    background-color: #fff;
    border-radius: 4px;
    padding: 0.75rem;
}

.conteudoEtapaStatus .nav-tabs {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    margin-bottom: 1rem;
}

.conteudoEtapaStatus .nav-tabs .nav-link.active {
    color: #fff;
    background-color: var(--dommus-color);
    border-color: var(--dommus-color);
}

.conteudoEtapaStatus .nav-tabs .nav-link {
    white-space: nowrap;
    color: var(--dommus-color-cinza);
    font-size: 14px;
}

.inputStyle {
    border:  none; 
    width: 100%;
    background-color: transparent; 

}

.inputStyle:hover {
    width: 100%;
    padding: 0.5rem 0 0.5rem 0.5rem;
    background-color: rgb(255, 255, 255); 
    border-radius: 4px;
}

.inputStyle:focus {
    width: 100%;
    padding: 0.5rem 0 0.5rem 0.5rem;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid rgb(170, 168, 168);
    border-radius: 4px;
    box-sizing: border-box;

}
