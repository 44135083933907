.input-show-name-evolucao{
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    border-radius: .25rem;
    color: #495057;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}