.automacao-condicionantes-section .dommus-toggle{
    border-left: 5px solid var(--dommus-color-green) !important;

}

.condicionantes-section{
    max-height: auto !important;
}

.condicionante-section-bloco{
    border-bottom: 1px solid gainsboro;
    padding-bottom: 20px;
}

.referencia-section{
    background-color: #ffffdd;
    border: 1px solid #ffb266;
    border-radius: 10px;
    padding: 10px;
}
.referencia-section .form-group {
    margin-bottom: 0px;
}