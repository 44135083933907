.title-atendimento-section{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  gap: 10px;
}

.title-atendimento-section .form-switch{
  padding: 0px !important;
  margin-right: 10px;
  align-items: center;
  display: flex;
  align-items: center;
  flex-direction: row;
}
