.areaApresentacao{
        padding-top: 15px;
        cursor: auto;
    }

    .textIntroducao{
        text-align: justify;
        text-justify: inter-word;
        color:#353252;
    }
    .icon_area_apresentacao{
        display: flex;
        align-items: center;
        max-width: 43px;
        cursor: auto;
        padding: 20px;
    }
    .text_icons_apresentacao{
        text-align: justify;
        text-justify: inter-word;
        padding-left: 10px;
        color:#353252;
        
    }

    .cardEndereco{
        cursor: auto !important;
    }
    .cardApresentacao{
        cursor: auto !important;
    }

    .btnfotos{
        width: 2rem;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: none;
        cursor: pointer;
        /* position:absolute; */
        z-index:1;
        margin: 5px;
    }

    .btnhoverexcluir:hover{
        background-color: #b61827 !important;
    }

    .btnhoverfundo:hover{
        background-color: var(--dommus-color) !important;
    }

    .btnfundo{
        background-color: var(--dommus-color) !important;
    }

    .btnhoverprincipal:hover{
        background-color: var(--dommus-color-2) !important;
    }

    .btnprincipal{
        background-color: var(--dommus-color-2) !important;
    }

    .iconhovercolor:hover{
        color: white !important;
    }
    .divremoverfotos{
        position:absolute;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        margin-top: 14%;
    }

    .anexoFotosIcon{
        display: inline-block;
    }

    .anexoFotosIcon:hover{
        cursor: pointer;
    }

    .galeria img{
        width: 300px!important;
        height: 330px !important;
    }

    .galeriaFotos{
        overflow-y: scroll;
        max-height: 450px;
    }