h2{
    font-size: unset;
    margin: 0;
}
.formCadastro{

}

.formCadastro label{
    font-weight: bold;
    color: #696969;
}

.formCadastro .row {
    border-bottom: unset;
}

.formCadastro .form-group{
    padding-top: 10px;
}
.formCadastro .lastRow{
    border-bottom: 0;
}
.formCadastro .formFooter{
    border-top: 1px solid #cccc;
    border-bottom: 0;
    padding-top: 10px;
}
.formCadastro .formFooter .col{
    justify-content: flex-end;
    align-items: center;
    display: flex;
}
.form-switch {
    padding-left: .8rem !important;
    padding-bottom: .8rem !important;
    font-weight: bold !important;
    font-size: 16px !important;
    color: #000 !important;
}