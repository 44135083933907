.card-file{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: fit-content;
    border-radius: 10px;
    padding: 10px;
    border: 1px solid gainsboro;
}

.card-file svg{
    cursor: pointer;
    color: red;
}