.divImg{
    margin: 0px 15px 15px 15px;
    border: 5px solid #9e9b9b;
    display: flex;
    justify-content: end;
    background-color: #9e9b9b;
    
}
.divImgBanner{
    width: 100%;
}
/* .divImg:hover{
    cursor: pointer;
} */

.modalImgPortal{
    max-width: 100%;
    width: 250px;
    height: 250px;
    object-fit: cover;
}
.modalImgPortalBanner{
    max-width: 100%;
    width: 100%;
    height: 140px;
    object-fit: cover;
}
.nome-arquivo {
    color: #868aa8 !important;
    font-size: 12px;
    font-weight: 400 !important;
    margin-bottom: 0;
    word-wrap: break-word!important;
}
.icone-acao-galeria-inativo {
    color: var(--dommus-color)
}

.save-button-nome{
    background-color: var(--dommus-color);
}

.borda-vermelha {
    border: 5px solid var(--danger)!important;
}