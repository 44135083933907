.automacao-dados-bloco .dommus-toggle{
    border-left: 5px solid rgb(244, 200, 118) !important;
}

.switch-automacao-unica{
    display: flex;
    justify-content: start;
    align-items: center;
    height: auto;
}
.switch-automacao-unica .form-switch {
    padding-left: 0 !important;
}
.switch-automacao-unica .form-switch span{
    font-weight: 400;
    font-style: normal;    
}