.distribuicaoInterna-cadastro {
  margin-right: 10px;
  /* height: 82%; */
}

.box-altura {
  height: 90.5%;
}

.distribuicaoInterna-cadastro .form-group {
  margin-bottom: 0px !important;
}

.separatorSelect {
  border-bottom: 1px solid #eee;
  width: 100%;
  align-self: center;
}

.row-cadastro-inicio {
  background-color: #f8f8f8;
  padding-top: 5px;
}

.row-cadastro-meio {
  margin-top: 5px;
  background-color: #f8f8f8;
  padding-top: 5px;
}

.row-cadastro {
  background-color: #f8f8f8;
}

.formFooter.mt-2.cadastro {
  border-top: 1px solid #cccc;
  border-bottom: 0;
  padding-top: 10px;
  margin-top: 0px !important;
}

.formFooter.mt-2.cadastro button {
  width: 100%;
}

.altura {
  /* height: 40% !important; */
  /* max-height: 60%; */
}