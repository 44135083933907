.lista-graficos-section{
  background-color: #eee !important;
}

.preview-grafico-section{
  margin-bottom: 10px;
  border-radius: 1rem;
}

.preview-grafico-section .bloco-grafico-preview{
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.preview-grafico-section .bloco-grafico-preview .titulo-grafico{
  font-size: 1.2rem;
  font-weight: 700;
}

.imagem-preview-grafico{
  max-height: 10rem;
}
