.powerBi {

    width: 120px;
    border: 1px solid #183B64;
    border-radius: 5px;
    background-color: #183B64;
    color: #fff;
    margin: 0px;
    padding: 10px;
    height: 35px;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    line-height: 100%;
    cursor: pointer;
    transition: all 0.5s linear;
}

.powerBi:hover{
    background-color: #fff;
    color: #183B64;
}