.slide-lead {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    background-color: var(--dommus-color);
    /*padding: 20px;*/
    min-height: 500px;
}

.slide-lead .dados {
    text-align: center;
}


.slide-lead .dados div {
    font-size: 1.3em;
    font-weight: bold;
    color: white;
    padding: 5px 0;
}

.slide-lead .dados .midia-icone {
    font-size: 2.5em;
}

.slide-lead .acoes {
    text-align: center;
    margin-top: 40px;
}

.slide-lead .acoes .btn {
    margin-right: 10px;
    padding: 13px 30px;
}

.__react_component_tooltip {
    font-size: 14px !important;
}

.historico-lead {
    max-height: 1750px;
    overflow: auto;
}