.form-dados-section{
    margin-bottom: 5px;
    border-bottom: 1px solid gainsboro;
}

.form-dados-section .label-section{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.form-dados-section .btn-gerar-access-token{
    background-color: var(--dommus-color);
    color: white;
    font-weight: 600;
    width: 100%;
}