.conteudoPagina{
    display: block;
}

.conteudoOportunidade{
    overflow-y: auto !important;
}
.tituloPagina{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    background-color: #fff;
    font-weight: 700;
    border-radius: 5px;
    border: 1px solid #ddd;
    margin-bottom: 15px;
    font-size: 1rem;
    line-height: 1.5;
    color: #212529;
}

.titulo-pagina-mobile{
    display: flex;
    flex-direction: column;
    padding: 15px;
    background-color: #fff;
    font-weight: 700;
    border-radius: 5px;
    border: 1px solid #ddd;
    margin-bottom: 15px;
    font-size: 1rem;
    line-height: 1.5;
    color: #212529;    
    gap: 5px;
}

.titulo-pagina-mobile .nome-oportunidade-mobile{
    font-size: 1.1rem;
    text-wrap: wrap;
}

.titulo-pagina-mobile .form-switch{
    padding-bottom: 0 !important;
}

.titulo-pagina-mobile .BtnDesistencia{
    width: 90%;
    align-self: center;
    margin-bottom: 10px;
    border: none;
    border-bottom: 2px solid #FF6B6B;
    padding: 5px;
    color: #FF6B6B;
    background-color: #fff;
    height: 33px;
    font-weight: bold;
    font-size: 18px;
    display: flex;
    justify-content: center;
    justify-self: center;
    align-items: center;
    cursor: pointer;
}

.btn-dados-desistencia-mobile{
    display: none;
}

.coluna{
    margin-right: 20px;
    width: 355px;
}
.react-tabs{
    white-space: normal;
}
.react-tabs__tab-list{
    background-color: #fff;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #ddd;
}
.status{
    background-color: #bae503;
    height: 33px;
    color: #fff;
    font-weight: bold;
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
}
.tituloPagina .codigo{
    color: #f44336;
}

.codigoMobile{
    color: #f44336;
}

.tituloPagina .nomeOportunidade{
    text-transform: uppercase;
}
.tituloPagina .nomeOportunidadeMobile{
    display: none;
}

.statusCodigo{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.statusCodigo span{
    cursor: pointer;
    font-size: 25px;
}

.aba-menu-superior-detalhe-opv {
    border: 1px solid #183b64;
    color: #183b64;
}

.react-tabs__tab-list li{
    display: inline-block;
    list-style: none;
    padding: 10px 15px;
    line-height: 1;
    cursor: pointer;
    margin: 0 5px;
    border-radius: 5px;
    font-size: 14px;
    transition: 200ms ease-in-out;
}
.docProposta{
    background-color: #12a2b8;
    border: 1px solid #12a2b8 !important;
    color: #fff !important;
}
.abaDoc{
    display: flex;
    align-items: center;
}
.iconTab{
    font-size: 20px;
    margin-right: 25px;
    cursor: pointer;
}
.docProposta:hover{
    background-color: #138496;
    border: 1px solid #138496;
    color: #fff;
}
.react-tabs__tab-list{
    display: flex;
    justify-content: space-between;
}
.react-tabs__tab-list li:first-child{
    margin-left:0px;
}

.react-tabs__tab--selected{
    background-color: #f7941d;
    border: 1px solid #f7941d !important;
    color: #fff !important;
}
.react-tabs__tab--selected:hover{
    background-color: #f7941d !important;
    color: #fff !important;
}

.react-tabs__tab-panel{
}
.toggleCadastro  > div{
    max-height: 235px;
    overflow: auto;
}
.desistenciaMobile{
    display: none;
}
.container-abaDocMobile{
    display: none;
}
.container-abaDocPropostaMobile{
    display: none;
}

.statusCodigo .BtnDesistencia{
    border: none;
    border-bottom: 2px solid #FF6B6B;
    /* border-radius: 5px; */
    margin-right: 15px;
    padding: 5px;
    color: #FF6B6B;
    background-color: #fff;
    height: 33px;
    font-weight: bold;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

@media (max-width:767px) {
    .tituloPagina{
        padding: 10px;
        font-size: 15px;
        margin-bottom: 5px;
        display: flex;
        flex-direction: column;
        /*margin-top: 20px;*/
    }
    .tituloPagina div{
        width: 100% !important;
        justify-content: space-evenly !important;
    }
    .tituloPagina .nomeOportunidade{
        display: none;
        flex-direction: column;
    }

    .tituloPagina .codigo{
        display: flex;
        justify-content: center;
        /* color: #12A2B8; */
        color: #f44336;
    }
    .statusCodigo span:first-child{
        font-size: 15px;
        margin-right: 5px;
        display: none;
    }
    .statusCodigo span{
        font-size: 16px;
        width: 100%;
        padding: 7px;
    }
    .desistenciaMobile{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border-radius: 5px;
        border: 1px solid red;        
        line-height: 3;
        width: 100%;
        outline: none;
    }
    .desistenciaMobile:focus{
        outline: none;
    }
    .desistenciaMobile p{
        margin-bottom: 0px;
        color: red;
    }
    .statusCodigo .status{
        width: 100%;
        height: 0%;
        margin-bottom: 10px;
    }
    .statusCodigo{
        display: flex;
        flex-direction: column;
    }
    .react-tabs__tab-list{
        display: flex;
        flex-direction: column;
    }
    .react-tabs__tab-list li{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px;
    }

    .iconTab {
        margin-right: 15px;
        display: none;
    }
    .abaDoc{
        display: flex;
        justify-content: flex-end;
        display: none;
    }

    .abaBotoes{
        display: flex;
        overflow: auto;
        gap: 10px;
    }

    .abaBotoes li{
        flex-basis: 48%;
        margin: 0px;
    }

    .abaDoc .docProposta{
        width: 62%;
    }
    .container-abaDocMobile{
        display: flex;
        justify-content: space-between;
        margin: 10px 0px 15px 0px;
    }
    .container-abaDocPropostaMobile{
        display: flex;
        list-style: none;
        margin-bottom: 15px;
        line-height: 3;
        cursor: pointer;
        border-radius: 5px;
        transition: 200ms ease-in-out;
        border-radius: 5px;
        justify-content: center;
    }
    
    .docProposta {
        background-color: #12a2b8;
        border: 1px solid #12a2b8 !important;
        color: #fff !important;
        width: 100%;
        font-size: 1.1rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }

    .docProposta:hover {
        background-color: #12a2b8;
        border: 1px solid #12a2b8 !important;
        color: #fff !important;
    }

    .container-abaDocMobile .iconTabMobile{
        font-size: 20px;
        border: 1px solid #ddd;
        border-radius: 5px;
        background-color: #fff;
        width: 47%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 45px;
        text-decoration: none;
        outline: none;
    }

    .btn-dados-desistencia-mobile{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border-radius: 5px;
        border: 1px solid red;        
        line-height: 3;
        width: 100%;
        outline: none;
        color: red;
    }
}
.form-switch {
    padding-top: .6rem !important;
    padding-right: 1rem !important;
}
.warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
    cursor: pointer;
}
.alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}