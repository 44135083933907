.form-busca-opv-of-historico{
    border: 2px solid #ccc;
    border-radius: 4px;
    padding: 10px;
}


.form-busca-opv-of-historico:hover{
    box-shadow: 1px 1px 4px #ccc;
}
.button-left{
    position: relative;
    left: 90%;
}