.automacao-agendamento-section .dommus-toggle{
    border-left: 5px solid var(--dommus-color-secondary) !important;
}

.automacao-agendamento-section .grade-horario-section .dommus-toggle{
    border-left: 1px solid #ddd !important ;
    margin-top: 13px;
    margin-bottom: 0 !important;
}

.radio-agendamento{
    display: flex;
    flex-direction: column;    
    gap: 10px;
    margin-top: 10px;
    padding-left: 25px;
}

.radio-agendamento .form-check label, input{
    cursor: pointer !important;
}

.periodo-section{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.periodo-section .btn-grade-horario{
    background-color: var(--dommus-color-2);
    border: 1px solid var(--dommus-color-2);
    margin-bottom: 1rem;
}

.periodo-section .periodo-fields{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
}

.periodo-section .periodo-item{
    display: flex;
    flex-direction: row;
    align-items: baseline;    
    justify-content: center;
    gap: 10px;
}

.automacao-agendamento-section .grade-horario-section .grade-title{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.grade-horario-section .grade-horario-dia{
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 15px;
}

.grade-horario-section .grade-horario-dia label{
    font-size: 0.9rem;
}

.grade-horario-section .grade-horario-dia .grade-horario-horario{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}