.facebookmodel
    .card-body{
        align-items: center;
        align-content: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        color: #046ee5;
        max-height: unset !important;
    }
    .error{
        text-align: center;
    }

    .botaoCriarCampanha{
        padding: 10px;
        border: 1px solid #ccc;
        background-color: #0069D9;
        color: #fff;
        border-radius: 5px;
    }

    .botaoLogoutFacebook{
        padding: 10px;
        border: 1px solid #ccc;
        background-color: #dc3545;
        color: #fff;
        border-radius: 5px;
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .botaoLogoutFacebook:hover{
        color: #fff;
    }

    .textosFacebook{
        background-color: #ddd;
        padding: 10px;
        border-radius: 5px;
        color: #000;
    }

    .textosFacebook span{
        color: #676767;
    }

    .containerPaginas{
        padding: 10px;
        margin-bottom: 10px;
        margin-top: 10px;
    }