div .divfume:last-child{
   /* width: 500px;
   height: 500px;
   background-color: green;
   position: relative;
   margin-top: -130px;
   margin-left: 20px; */
}

aside.sidebar {
    margin-top: 75px;
    min-width: 255px;
    position: relative;
}
aside.sidebar.hide{
    min-width: 0;
}
aside.sidebar .sidebar-wrapper {
    position: fixed;
    left: 0;
    width: 100%;
    max-width: 250px;
    height: calc(100vh - 75px);
    background-color: #fff;
    overflow: hidden;
    border-right: 1px solid #f2f3f8;
}

aside.sidebar .sidebar-wrapper.is-closed{
    max-width: 50px;
}
aside.sidebar .sidebar-wrapper.is-closed .sidebar-title span{
    display: none;
}
aside.sidebar .sidebar-wrapper.is-closed header{
    display: none;
}
aside.sidebar .sidebar-wrapper.is-closed ul{
    display: block!important;
}
aside.sidebar .sidebar-wrapper.is-closed ul > li{
    padding: 0;
    justify-content: center;
    transition: var(--smooth);
}
aside.sidebar .sidebar-wrapper.is-closed ul > li a{
    justify-content: center;
}
aside.sidebar .sidebar-wrapper.is-closed ul > li:hover > a{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #edeeef;
}
aside.sidebar .sidebar-wrapper.is-closed ul > li a p{
    display: none;
}
aside.sidebar .sidebar-wrapper.is-closed ul > li a i{
    margin-right: 0;
}
aside.sidebar .sidebar-wrapper.is-closed .copy{
    display: none;
}
aside.sidebar .sidebar-title {
    font-size: 16px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f2f3f8;
}
aside.sidebar .menu-structure {
    max-height: 295px;
    overflow: initial;
    padding-bottom: 15px;
}
aside.sidebar header {
    padding: 15px;
    border: 0;
    border-bottom: 1px solid #f2f3f8;
    border-radius: 0;
    font-weight: 700;
    font-size: 14px;
}
aside.sidebar .menu-structure ul:last-child {
    border-bottom: 0;
}
aside.sidebar ul > li {
    height: 45px;
    display: flex;
    align-items: center;
    padding: 0 15px;
}
aside.sidebar ul > li a {
    color: #868aa8;
    font-size: 16px;
    width: 100%;
    height: 100%;
    display: flex;
    text-decoration: none;
    align-items: center;
}
aside.sidebar ul > li a:hover {
    color: #000;
    text-decoration: none;
}
aside.sidebar .info-structure {
    position: absolute;
    width: 100%;
    bottom: 0;
    background-color: #fff;
    border-top: 1px solid #f2f3f8;
}
aside.sidebar ul {
    padding: 0;
    list-style: none;
    border-bottom: 1px solid #f2f3f8;
    margin-bottom: 0;
}

aside.sidebar .copy {
    font-size: 12px;
    padding: 15px;
}
aside.sidebar a {
    text-decoration: none;
    color: #007bff;
}
aside.sidebar .info-structure ul li p{
    margin: 0;
    margin-left: 10px;
}
aside.sidebar .menu-structure ul li p{
    margin: 0;
    margin-left: 10px;
}

.menu-sections section {
    position: fixed;
    left: 0;
    top: 75px;
    min-width: 300px;
    height: 100%;
    background-color: #fff;
    transform: translateX(-555px);
    z-index: 9;
    transition: var(--smooth);
    left: 49px;
}
.menu-sections:nth-child(2) section.open {
    /* position: fixed;
    left: 0;
    top: 75px;
    min-width: 600px;
    height: 100%;
    z-index: 9; */
    background-color: #fff !important;
    left: 349px;
}
.menu-sections section header {
    padding: 15px;
    border-bottom: 1px solid #f2f3f8;
    font-weight: 700;
    font-size: 14px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}
.menu-sections section.open {
    transform: translateY(0);
    border-left: 1px solid #f2f3f8;
}
.close-pane{
    cursor: pointer;
}
.menu-sections section ul li span {
    color: #868aa8;
    font-size: 12px;
    width: 20%;
}
.menu-sections{
    display: block;
}
.menu-sections section h6 {
    font-weight: 700;
    font-size: 12px;
    padding: 15px;
    border-bottom: 1px solid #f2f3f8;
    height: 45px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    color: #183b64;
}
.menu-sections section ul {
    padding: 0;
    margin-bottom: 0;
    list-style: none;
    overflow-y: scroll;
    height: 80%;
}
.menu-sections section ul li a {
    font-size: 12px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    color: #868aa8;
}
.menu-sections section ul li a svg {
    margin-right: 15px;
}
.menu-sections section ul li a p {
    margin-bottom: 0;
}
.menu-sections section ul li {
    height: 50px;
    display: flex;
    padding: 0 15px;
    align-items: center;
    border-bottom: 1px solid #f2f3f8;
}

@media (max-width:767px) {
    #sidebarMobile{
        display: none;
    }
    aside.sidebar .sidebar-wrapper.is-closed{
        position: relative;
    } 
    aside.sidebar{
        margin-top: 57px;
        min-width: 92vw;
    }
    aside.sidebar .sidebar-wrapper{
        max-width: 100%;
        z-index: 1;
    }
    .menu-sections section{
        top: 57px;
        left: 0;
        width: -webkit-fill-available;
    }
    aside.sidebar a{
        display: none;
    }

    .pesquisa-geral-mobile{
        margin: 5px 5px;
    }
    .pesquisa-geral-mobile .form-group{
        margin-bottom: 0;
    }
    .pesquisa-geral-mobile .clean-input{
        color: #666;
        top: 10px;
    }

    .pesquisa-geral-mobile .input-pesquisa-control{
        background-color: #f8f8f8;
        color: #666;
    }
    .pesquisa-geral-mobile .input-pesquisa-control:focus{
        background-color: #f8f8f8;
        color: #666;
    }

    .pesquisa-geral-mobile .search-wrapper{
        top: 6.5rem !important;
    }
    .pesquisa-geral-mobile .search-wrapper .resultado-pesquisa-itens a{
        display: flex !important;
    }
    .pesquisa-geral-mobile .search-wrapper .resultado-pesquisa-itens .card-pesquisa-geral{
        flex-direction: column;
        align-items: center;
        padding: 10px 5px;
    }
    .pesquisa-geral-mobile .search-wrapper .resultado-pesquisa-itens .card-pesquisa-geral .row{
        margin-left: 0 !important;
    }



}


section.equipes {
    position: fixed;
    right: 0;
    top: 75px;
    min-width: 50%;
    height: calc(100% - 75px);
    background-color: #fff;
    transform: translateX(100%);
    z-index: 12;
    transition: var(--smooth);
    padding: 10px;
    overflow: auto;
}

.divmenufume.open {
    transform: translateX(0);
    border-left: 1px solid #f2f3f8;
}

.divmenufume header {
    padding: 15px;
    border-bottom: 1px solid #f2f3f8;
    font-weight: 700;
    font-size: 14px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.divmenufume header .close-pane {
    height: 100%;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    width: 55px;
    justify-content: center;
}

.sidenav-overlay-menu.open {
    display: block;
    opacity: 1;
}
.sidenav-overlay-menu {
    position: fixed;
    top: 0;
    left: 50px;
    right: 0;
    opacity: 0;
    height: 120vh;
    background-color: rgba(0,0,0,.5);
    z-index: 7;
    display: none;
    transition: var(--smooth);
}
.linkMapeamento{
    display: flex;
    justify-content: space-between;
}