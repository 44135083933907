.permission-block button.active{
    background-color: var(--dommus-color) !important;
}

.checkbox-permissao {
    margin-right: 10px;
    transform: scale(1.05);
}
.checkbox-permissao:last-child {
    margin-right: -10px;
}
