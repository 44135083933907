.automacao-itens{
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 15px;
}

.footer-automacao-funil{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 0px;
}