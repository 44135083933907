.form-cadastro{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: end;
    gap: 20px;
}

.form-cadastro .text-field{
    flex-basis: 44%;
}