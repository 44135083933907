.resultado-pesquisa-itens{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
}

.resultado-pesquisa-itens .spinner{
    display: flex;    
    margin-top: 20px;
    width: 100%;
    align-items: center;
    justify-content: center;
    color: var(--dommus-color);
    font-size: 20px;
}

.resultado-pesquisa-itens .spinner .spinner-border{
    width: 3rem;
    height: 3rem;
}
