.titulo-secundario tr:hover {
    color: inherit!important;
    background-color: inherit!important;
}
.titulo-secundario th{
    border: 1px solid var(--secondary)!important;
}
.custo-campanha {
    background-color: #fffdea;
}
.espacameto-custos-campanha {
    padding: 12px!important;
}
.linhas-tabela-midia {
    
}