#step-selector {
    max-width: 15vh;
    margin-bottom: 10px;
    margin-left: 15px;
}


.dommus-interval-step {
    max-width: 175px;
    display: flex;
    flex-direction: column;
}

.dommus-interval-step label {
    margin-bottom: 0px;
    font-size: 13px;
    color: #6a6a6a;
}

.dommus-interval-step-group {
    display: flex;
}

.interval-step-item {
    cursor: pointer;
    height: 25px;
    border: 1px solid #ccc;
    width: 58px;
    text-align: center;
}

.interval-step-item:hover {
    opacity: 0.9;
}

.interval-step-item.active {
    background-image: none;
    box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
    background-color: #e6e6e6;
    border-color: #adadad;
}

.interval-step-item:first-child {
    border-radius: 4px;
    border-right: none;
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
}

.interval-step-item:last-child {
    border-radius: 4px;
    border-left: none;
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
}

.dommus-custom-options {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
    justify-content: space-between;
}

.agenda-do-usuario {
    color: #fff;
    background-color: #28a745;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    z-index: 99999;
}

.agenda-do-usuario:hover {
    opacity: 0.8;
}

.navigation-header-icons{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 20px 0px 20px;
}

.navigation-header-icons:last-child{
    background-color: #28a745;
}

.dommus-type-options-section{
    display: flex;    
    gap: 10px;    
}

.dommus-type-options {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
}


.dommus-type-options label {
    margin-bottom: 0px;
    font-size: 12px;
    color: #6a6a6a;
}

.dommus-type-options-items {
    display: flex;
    flex-direction: row;
}

.dommus-type-options-item {
    cursor: pointer;
    height: 25px;
    border: 1px solid #ccc;
    background-color: #f8f8f8;
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    font-weight: 600;
}

.dommus-type-options-item:hover {
    opacity: 0.9;
}

.dommus-type-options-item.active {
    background-image: none;
    box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
    background-color: var(--dommus-color);
    color: white;
    border-color: #adadad;
}

.dommus-type-options-item:first-child {
    border-radius: 4px;
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
}

.dommus-type-options-item:last-child {
    border-radius: 4px;
    border-left: none;
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
}

.dommus-options {
    display: flex;
    flex-direction: row;
}


.rbc-event-label {
    display: none !important;
}

.rbc-events-container {
    font-size: 11px;
    line-height: 1.25;
    flex-wrap: nowrap;
}

.rbc-events-container div {
    height: 40px !important;
}

.rbc-events-container div:hover {
    height: auto !important;
    z-index: 999;
    width: auto !important;
}

.rbc-event {
    --event-color: #03A9F4;
    --event-color-hover: #0575a8;
    background-color: var(--event-color)!important;
    border: 2px solid #fff !important;
}

.rbc-event:hover {
    background-color: var(--event-color-hover)!important;
}

.rbc-agenda-table tr{
    color: #000 !important;
    background-color: #fff !important;
}

.rbc-toolbar{
    z-index: 0!important;
}


@media (max-width: 767px){
    .dommus-type-options-section{
        flex-wrap: wrap;
        align-items: center;
        justify-content: start;
    }

    .dommus-options{
        flex-wrap: wrap;
        justify-content: flex-start !important;
    }
}