.geracao-access-token-section {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
}

.geracao-access-token-section .btn-gerar-access-token{
    background-color: var(--dommus-color);
    color: white;
    font-weight: 600;
    width: 100%;
}