.questionarios-section .nav-tabs .nav-link.active {
    color: #fff;
    background-color: var(--dommus-color);
    border-color: var(--dommus-color);
}
.questionarios-section .nav-tabs .nav-link {
    white-space: nowrap;
    color: var(--dommus-color-cinza);
    font-size: 14px;
}

.dropdown-toggle::after{
    display: none !important;
}