
.add-linha{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    color: var(--dommus-color-cinza);
    font-size: 0.9rem;
    padding: 0px 10px;
}

.add-linha span{
    padding-top: 2px;
}
