.csvPagina .enviar{
    padding-right: 25px;
}
.csvPagina .hidden{
    display: none;
}
.csvPagina .csvReader{
    padding: 5px;
}
.csvPagina .DommusToggle{
    padding-top: 15px;
}
.csvPagina .cabecalho{
    /*text-align: center;*/
    font-weight: bold;
}
.csvPagina .form-label{
    /*text-align: center;*/
}
.csvPagina .header{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%)
}

@media(max-width: 767px){
    .csvPagina .form-label{
        font-size: 15px;
    }
}
