.Luminoso24h{
  height: 15px;
  width: 15px;
  margin-right: 5px;
  border-radius: 10px;
  border: 1px solid #ddd;
}

.DoubleArrow{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.iconDouble{
  margin-bottom: -7px;
}

.empreendimento-nome{  
  overflow: hidden;
  white-space:nowrap;
  text-overflow:ellipsis;
}

.tags-space{
  width: 45%;
}

.tags-space .tag-section{
  justify-content: end;
}

.estiloCardSubstatus{
  /* background-color: #d5e3e0; */
  /* padding: 5px 35px; */
  display: flex;
  /* border-radius: 50px; */
}