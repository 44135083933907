.card-redistribuicao{
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 65vh;  
    padding-left: 20px;  
    padding-right: 20px;  
}

.footer-redistribuir{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 0px;
}

.linha-menos-destaque{
    color: #999;
    text-align: start;
}

.td-rows{
    width: 100%;
    display: flex;
    flex-direction: row;    
    gap: 20px;
}

.botoes-redistribuir{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
}

.numero-indicador-distribuicao-positivo{
    font-size: 1.2rem;
    font-weight: 700;    
    color: green;
}

.numero-indicador-distribuicao-negativo{
    display: inline;
    font-size: 1.2rem;
    font-weight: 700;    
    color: red;
    margin-left: 5px;
    margin-right: 5px;
}

.btn-erro-distribuicao{
    display: flex;
    justify-content: center;
    align-items: center;
    background: red;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    font-size: 25px;
    font-weight: 800;
}

.btn-erro-distribuicao:hover{
    background-color: rgb(214, 0, 0);
}
.btn-reenviar-nao-distribuidos{
    margin-left: 15px;
}

.btn-reenviar-nao-distribuidos:disabled{
    cursor: not-allowed;
}