.biblioteca-graficos {
    width: 0;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    background-color: #eee;
    border-left: 1px solid #000;
    overflow-x: hidden;
    transition: 0.5s;
    margin-top: 75px;
    padding: 20px 10px 0px 10px;
    z-index: 5;
    transform: translateX(100%);
    width: 30%;
    z-index: 2000;
}

.biblioteca-graficos .title-biblioteca-grafico{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0px 20px;
  margin-bottom: 10px;
}

.biblioteca-graficos.aberta {
    transform: translateX(0%);
}

.pesquisa-grafico:focus {
    box-shadow: 0 2px 0 0 #183b64!important;
    border-color: #183b64!important;
}

.label-formulario {
    color: #868aa8 !important;
    font-size: 0.7rem;
    font-weight: 400 !important;
    margin-bottom: 0;
}

a.nav-item.nav-link.active {
    background-color: var(--dommus-color) !important;
    color: #fff !important;
}
a.nav-item.nav-link {
    color: var(--secondary) !important;
}
