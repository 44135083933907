.atendimentosdoDiaContent {
    overflow-y: auto;
    height: auto;
    .tebelaAtendimentosdoDiaContent{
      text-align: center;
      margin-left: 1rem;
      width: -webkit-fill-available;
      margin-right: 1rem;
    }
  }
