.card-pesquisa-geral {
    display: flex;    
    flex-direction: row;
    width: 100%;
    border: 1px solid #b1b1b1;
    border-radius: 10px;
    padding: 10px 10px;
    cursor: pointer;
    transition: all 500ms;
}

.card-pesquisa-geral:hover{
    transform: translateY(-3px);
    box-shadow: 0 3px 5px rgb(0 0 0 / 13%);
    
}

.card-pesquisa-geral .midia-icone-pesquisa {
    display: flex;
    width: 10%;
    justify-content: center;
    align-items: center;
    font-size: 75px;
}

.opv-section{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.opv-section .opv{
    display: flex;    
    font-size: 18px;
    font-weight: 700;
    color: var(--dommus-color-red);
}

.info-section{
    width: 100%;    
}

.info-section .contato-info {
    display: flex;
    flex-direction: column;
    gap: 1px;
    color: #666;
    font-size: 16px;
    width: 100%;
}
.info-section .contato-info .item-contato{
    display: flex;
    flex-direction: row;
    gap: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.info-section .contato-info .item-contato .span-replace{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.info-section .oportunidade-section .item-oportunidade{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;    
    color: #666;
    font-size: 16px;
}

.info-section .oportunidade-section .etapa-item{
    padding: 3px;
    border-radius: 10px;
    color: white;    
}

.data-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1px;    
    color: #464545;
    font-size: 14px;
}

.data-section  .badgeTempo{
    align-items: center;
    display: flex;
    justify-content: center;
}

.data-section .badgeTempo .data svg{
    margin-right: 5px;
}
.data-section .etapa-item{
    width: 100%;
    border-radius: 10px;
    padding: 3px;
    color: white;
    font-weight: 700;
    font-size: 15px;
}

.dados-pesquisa-geral{
    padding-left: 10px;
}

mark{
    padding: 1px 0px;
    background-color: var(--dommus-color-2);
    border-radius: 5px;
    color: white;
    font-weight: 600;
}


