.areaSobre{
    display: block;
    position: relative;

}
.buttonEditarSobre{
    display: flex;
    gap: 10px;
    cursor: pointer;
    z-index: 1;
    margin-top: -3.2rem;
    margin-right: 40px;
}

.buttonFullScreen{
    cursor: pointer;
    z-index: 1;
    margin-top: -3.2rem;
    margin-right: 10px;
}

.linhaBotaoEditarSobre{
    justify-content: flex-end;
}

.buttonEditarAtrativos{
    cursor: pointer;
    z-index: 1;
    margin-top: -3.2rem;
    margin-right: 40px;
}
.linhaBotaoEditarAtrativos{
    justify-content: flex-end;
}

.areaSobre .buttonEditar{
    position: absolute;
    right: 15px;
    
}
