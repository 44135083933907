.empreendimento-section{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    height: 100%;
}

.empreendimento-section .badge{
    padding: 5px;
    font-size: 14px;
    color: white;
}