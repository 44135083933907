.dommus-toggle{
    transition: var(--smooth);
}
.dommus-toggle i:hover,
.dommus-toggle svg:hover{
    cursor: pointer;
}

.dommus-toggle.listen-all:hover{
    cursor: pointer;
}

.dommus-toggle.arrow{
    border-radius: 5px;
}
header.dommus-toggle.is-secondary{
    border: 1px solid var(--dommus-color)!important;
    color: var(--dommus-color)!important;
}
header.dommus-toggle{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px 5px 0 0;
    font-weight: 700;
    color: var(--dommus-color-dark);
    font-size: 14px;

}
header.dommus-toggle.arrow{
    border-radius: 5px;
    margin-bottom: 15px;
}

header.dommus-toggle:not(.ico-before) .ico-toggle{
    content: '\f107';
    float: right;
    font-family: 'Font Awesome 5 Free';
    color: #000;
    transition: var(--smooth);
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ddd;
    border-radius: 5px;
    line-height: 1;
    font-size: 12px;
}
header.dommus-toggle.ico-before{
    justify-content: flex-start;
}

header.dommus-toggle.ico-before::before{
    content: '\f107';
    float: left;
    font-family: 'Font Awesome 5 Free';
    color: #000;
    transition: var(--smooth);
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ddd;
    border-radius: 5px;
    line-height: 1;
    font-size: 12px;
    margin-right: 10px;
}

header.dommus-toggle.ico-before.plus-ico::before{
    content: '\f067';
}

header.dommus-toggle i{
    margin-right: 10px;
}
header.dommus-toggle + div{
    padding: 15px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 0 0 5px 5px;
    border-top: 0;
    margin-bottom: 15px;
    width: 100%;
}

.header.dommus-toggle + div label{
    color: #868aa8;
    font-size: 12px;
    margin-bottom: 0;
}
.inner-toggle-container{
    background-color: #f2f3f8 !important;
}

.inner-toggle-container .inner-toggle-container{
    background-color: #F9FAFF !important;
}

.dommus-toggle-icones{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}
