.input-time{
    display: flex;
    flex-direction: row;    
    border: 1px solid #ced4da;
    border-radius: 5px;
    padding: 5px;
}

.input-time input{
    text-align: center;
    width: 50%;
    border: none;
}

.input-time input::-webkit-outer-spin-button,
.input-time input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-time input::placeholder{
  font-size: 14px;
}
.input-time-disabled{
  background-color: #e9ecef;
}