.conteudo .nav-tabs {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    margin-bottom: 1rem;
}

.conteudo .nav-tabs .nav-link.active {
    color: #fff;
    background-color: var(--dommus-color);
    border-color: var(--dommus-color);
}

.conteudo .nav-tabs .nav-link {
    white-space: nowrap;
    color: var(--dommus-color-cinza);
    font-size: 14px;
}
.modal-header{
    align-items: center;

}
.modal-title h2{
    font-size: 14px;
    font-weight: 700;
}
@media (min-width:992px) {
    .modal-lg {
        max-width: 1200px;
    }
}