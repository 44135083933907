.barra-titulo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-bottom: 15px;

}
.btnVisualiza{
    cursor: pointer;
    font-size: 2rem;

}

.barra-titulo .item-barra {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    background-color: #fff;
    border-radius: var(--dommus-b-radius);
    border: 1px solid #ddd;
    transition: var(--smooth);
    height: 52.13px;
    width: 52px;
    margin-left: 15px;
}

.barra-titulo .item-barra:first-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 15px;
    background-color: #fff;
    border-radius: var(--dommus-b-radius);
    border: 1px solid #ddd;
    margin-left: 0;
    font-weight: 700;
    font-size: 14px;
    height: 100%;
}

.barra-titulo .item-barra.btn svg {
    cursor: pointer;
}

.barra-titulo .item-barra.btn svg:hover {
    color: #f7941d;
}


.barra-titulo .item-barra.btn:hover {
    background-color: #4caf50;
}

.barra-titulo .item-barra.btn:hover svg {
    color: #ffffff;
}

.mobileBarraExtra{
    width: 100%;
    display: none;
}


@media (max-width:767px) {
    .barra-titulo .item-barra {
        padding: 5px;
        font-weight: 700;
        border-radius: 5px;
        margin: 0 10px 10px 10px;
    }
    .btn{
        display: block;
    }
    .barra-titulo:nth-child(2) .item-barra:first-child{
        display: none;
    }
    .barra-titulo .item-barra:nth-child(2){
        display: none;
    }
    .barra-titulo .item-barra:nth-child(3){
        display: none;
    }
    .barra-titulo .item-barra:nth-child(4){
        display: none;
    }
    .barra-titulo:first-child{
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 100%;
        width: 100%;
    }
    .barra-titulo .item-barra:first-child{
        display: flex;
        justify-content: space-between;
        margin-right: 0;
        height: 100%;
    }
    .barra-titulo .item-barra span{
        font-weight: 500;
        font-size: 13px;
        display: flex;
        align-items: center;
        width: 30%;
        justify-content: flex-end;
    }
    .mobileBarraExtra {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
    .mobileBarraExtra .btns-mobile-acao-section {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: space-between;
        margin: 0px 2px 10px 2px;
    }
    
    .mobileBarraExtra .btns-mobile-acao-section .btn-mobile-acao{
        display: flex;
        background-color: white;
        border-radius: 5px;
        flex: 1;
        justify-content: center;
        align-items: center;        
        height: 40px;        
    }

    .mobileBarraExtra .btns-mobile-acao-section .btn-mobile-acao .btn {
        padding: 0;
    }

    .mobileBarraExtra .btns-mobile-acao-section .btn-mobile-acao .btn-icon-mobile{
        display: flex;
        align-items: center;
        /* height: 36px;   */
    }

    .mobileBarraExtra .barra-titulo {
        margin-bottom: 0px;
    }
    .mobileBarraExtra .barra-titulo .item-barra {
        padding: 5px 10px;
        margin-bottom: 0px;
    }
}
