.conteudoNaoDistribuido{
    display: inline-flex;
    width: 100%;
}
.conteudoNaoDistribuido .midia{
    margin-right: 20px;
}
.dropdownNaoDistribuido{
    display: flex;
    justify-content: center;
}
.dropdownNaoDistribuido button:after{
   content: none; 
}
.buttonAutoNaoDistribuido{
    cursor: not-allowed !important;
    color: gray !important;
}

.linha-menos-destaque{
    color: #999;
    text-align: start;
}