.content {
    overflow: auto;
}

.cadastro {
    background-color: #fff;
    padding: 10px;
}

.cadastro .topo {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cadastro .topo h1 {
    color: #6a6a6a;
    font-size: 20px;
}

.cadastro .topo span {
    color: #f44336;
    font-weight: bold;
    margin-right: 10px;
    font-size: 20px;
}

.cadastro .topo svg {
    font-weight: bold;
    font-size: 20px;
    cursor: pointer;
}

.cadastro .cardLead {
    background-color: #f5f5f5;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
}

.cadastro .linha h1 {
    text-transform: uppercase;
}

.cadastro .botaoLeads {
    justify-content: flex-end;
    cursor: pointer;
    font-size: 20px;
}

.cadastro .cardEnvolvido {
    width: 265px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.cadastro .cardEnvolvido .linha {
    align-self: start;
}

.cadastro .cardEnvolvido .linha span {
    margin-right: 5px;
}

.cadastro .cardEnvolvido .fotoEnvolvido {
    font-size: 120px;

}

.distribuicaoInterna {
    margin-right: 10px;
    /* height: 57vh; */
}

.distribuicaoInterna header.dommus-toggle+div {
    /* height: 40.3vh; */
}

.ReactModalPortal {
    position: absolute;
    z-index: 9;
}

.rec-dot {
    margin-right: 15px;
    background-color: rgb(10 113 178) !important;
    box-shadow: 0 0 1px 3px #183b64 !important;
    color: #fff;
}

.rec-dot_active {
    background-color: rgb(241 157 31) !important;
    box-shadow: 0 0 1px 3px #f7941d !important;
}

.wrapper .bloco-conteudo .informacoes-lead-wrapper .leads .leads-wrapper .leads-navigation>li a span {
    font-weight: 700;
    margin-right: 5px;
}

.wrapper .bloco-conteudo .informacoes-lead-wrapper .leads .leads-wrapper .leads-navigation>li a.active {
    border: 1px solid #f7941d;
    background-color: transparent;
    color: #212529;
}

.wrapper .bloco-conteudo .informacoes-lead-wrapper .leads .leads-wrapper .leads-navigation>li a.active::after {
    content: "";
    position: absolute;
    top: 48px;
    right: -12px;
    width: 0px;
    z-index: 10;
    height: 0px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    /* border-left: 12px solid #f7941d; */
}

.wrapper .bloco-conteudo .informacoes-lead-wrapper .leads .leads-wrapper .leads-navigation>li a .info.horario {
    justify-content: flex-end;
    font-weight: 700;
    color: #f7941d;
}

.wrapper .bloco-conteudo .informacoes-lead-wrapper .leads .leads-wrapper .leads-navigation>li a:not(.active) {
    border: 1px solid #ddd;
}

.wrapper .bloco-conteudo .informacoes-lead-wrapper .leads .leads-wrapper .leads-navigation>li a .info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /* white-space: nowrap; */

}

.wrapper .bloco-conteudo .informacoes-lead-wrapper .leads .leads-wrapper .leads-navigation>li a .info.data {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}


.wrapper .bloco-conteudo .informacoes-lead-wrapper>div {
    border-radius: 5px;
}

.wrapper .bloco-conteudo .informacoes-lead-wrapper .pessoas-envolvidas .pessoas-wrapper .pessoa-carousel .pessoa .info-endereco .dados .item span {
    margin-bottom: 10px;
}

.wrapper .bloco-conteudo .informacoes-lead-wrapper .leads .leads-wrapper .leads-navigation {
    /* display: block !important; */
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
}

.nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.midia {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
}

.wrapper .bloco-conteudo .informacoes-lead-wrapper .leads .leads-wrapper .leads-navigation>li:not(:last-child) {
    margin-bottom: 5px;
}

.wrapper .bloco-conteudo .informacoes-lead-wrapper .leads .leads-wrapper .leads-navigation>li {
    position: relative;
    /* width: 41vh; */
}

.wrapper .bloco-conteudo .informacoes-lead-wrapper .leads .leads-wrapper .leads-navigation>li:not(:first-child) a {
    border: 1px solid #ddd !important;
    /* border-right: 1px solid #fff !important; */

}


.wrapper .bloco-conteudo .informacoes-lead-wrapper .leads .leads-wrapper .leads-navigation>li a.active {
    border: 1px solid #f7941d !important;
    background-color: transparent;
    color: #212529;
    height: 170px;
}

.wrapper .bloco-conteudo .informacoes-lead-wrapper .leads .leads-wrapper .leads-navigation>li a {
    border-radius: 5px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
    padding: 15px;
    color: #212529;
    position: relative;
}

.wrapper .bloco-conteudo .informacoes-lead-wrapper .leads .leads-wrapper .leads-navigation>li a .info.nome {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
    width: 20vw;
}

.wrapper .bloco-conteudo .informacoes-lead-wrapper .leads .leads-wrapper .leads-navigation>li a .info.email {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    /* number of lines to show */
    line-clamp: 1;
    /* -webkit-box-orient: vertical; */
    width: 15vw;
}
.wrapper .bloco-conteudo .informacoes-lead-wrapper .leads .leads-wrapper .leads-navigation>li a .info.email .email-dado {
    max-width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* number of lines to show */
    line-clamp: 1;
}

.wrapper .bloco-conteudo .informacoes-lead-wrapper .leads .leads-wrapper .leads-navigation>li a .info.telefone {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    /* number of lines to show */
    line-clamp: 1;
    /* -webkit-box-orient: vertical; */
    width: 15vw;
}

.wrapper .bloco-conteudo .informacoes-lead-wrapper .leads .leads-wrapper .leads-navigation>li a .info.empreendimento {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
    width: 22vw;
}

.wrapper .bloco-conteudo .informacoes-lead-wrapper .leads .leads-wrapper .leads-navigation>li a .info.campanha {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
    width: 22vw;
}

.data-cadastro-lead {
    position: absolute;
    bottom: 10px;
    right: 30px;
}

.midiaInfo {
    width: 19vw;
    display: flex;
    justify-content: space-between;
}

.midiaInfo .infos {
    margin-left: 15px;
    font-size: 12px;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #007bff;
}

.nav-pills .nav-link {
    border-radius: 0.25rem;
}

a, a:active, a:focus, a:hover, a:link, a:visited {
    text-decoration: none !important;
    outline: 0 !important;
}

.wrapper .bloco-conteudo .informacoes-lead-wrapper .leads .leads-wrapper .leads-content {
    /* border: 1px solid #ddd; */
    border-radius: 0 5px 5px 5px;
    background-color: #fff;
    margin-bottom: 20px;
}

.wrapper .bloco-conteudo .informacoes-lead-wrapper .leads .leads-wrapper .leads-content .tab-pane {
    height: 100%;
}

.tab-content>.tab-pane {
    display: none;
}

.tab-content>.active {
    display: block;
}

.bloco-conteudo .informacoes-lead-wrapper {
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    grid-gap: 30px;
}

.wrapper .bloco-conteudo .informacoes-lead-wrapper .leads .leads-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.wrapper .bloco-conteudo .informacoes-lead-wrapper .opv-lead-tablet .leads-wrapper {
    grid-template-columns: none;
}

nav nav-pills .leads-navigation {}

.wrapper .bloco-conteudo .informacoes-lead-wrapper .leads .leads-wrapper .leads-navigation {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    height: auto;
    /* overflow: auto; */
    margin-bottom: 5px;
    padding-bottom: 5px;
}


.dataTimeline {
    color: #999;
    font-size: 10px;
    text-align: right;
    bottom: -12px;
    right: 0px;
}

.wrapper .bloco-conteudo .informacoes-lead-wrapper .pessoas-envolvidas .pessoas-wrapper .pessoa-carousel .pessoa .infos {
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    padding: 15px 0;
    border-bottom: 1px solid #ddd;
    margin-bottom: 15px;
}

.wrapper .bloco-conteudo .informacoes-lead-wrapper .pessoas-envolvidas .pessoas-wrapper .pessoa-carousel .pessoa .info-contato .dados .item,
.wrapper .bloco-conteudo .informacoes-lead-wrapper .pessoas-envolvidas .pessoas-wrapper .pessoa-carousel .pessoa .infos .dados .item,
.wrapper .bloco-conteudo .informacoes-lead-wrapper .pessoas-envolvidas .pessoas-wrapper .pessoa-carousel .pessoa .info-endereco .dados .item {
    display: flex;
    flex-direction: column;
}

.wrapper .bloco-conteudo .informacoes-lead-wrapper .pessoas-envolvidas .pessoas-wrapper .pessoa-carousel .pessoa .infos .dados header {
    color: #f7941d;
}

.wrapper .bloco-conteudo .pessoas-wrapper>div header {
    font-size: 17px;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.pessoas-wrapper, .leads-wrapper {}

.wrapper .bloco-conteudo .informacoes-lead-wrapper .pessoas-envolvidas .pessoas-wrapper .pessoa-carousel .pessoa .info-contato .dados {
    padding-right: 30px;
}

.wrapper .bloco-conteudo .informacoes-lead-wrapper .pessoas-envolvidas .pessoas-wrapper .pessoa-carousel .pessoa .infos .dados .item span {
    margin-bottom: 10px;
}

.wrapper .bloco-conteudo .informacoes-lead-wrapper .pessoas-envolvidas .pessoas-wrapper .pessoa-carousel .pessoa .info-contato {
    padding-bottom: 15px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 15px;
}

.wrapper .bloco-conteudo .informacoes-lead-wrapper .pessoas-envolvidas .pessoas-wrapper .pessoa-carousel .pessoa .info-contato .dados .item span {
    margin-bottom: 10px;
}

.wrapper .bloco-conteudo .informacoes-lead-wrapper .pessoas-envolvidas .pessoas-wrapper .pessoa-carousel .pessoa .info-contato .dados header {
    color: #f7941d;
}

.wrapper .bloco-conteudo .informacoes-lead-wrapper .pessoas-envolvidas .pessoas-wrapper .pessoa-carousel .pessoa .info-endereco .dados header {
    color: #f7941d;
}
.wrapper .bloco-conteudo .informacoes-lead-wrapper .comentario-field-section {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-bottom: 15px;
}

.wrapper .bloco-conteudo .informacoes-lead-wrapper .comentario-field-section .comentario-field {
    width: 100%;
    cursor: text;
    max-width: 500px;
    overflow-y: auto;
    font-size: 14px;
    min-height: 30px;
    max-height: 160px;
    line-height: 20px;
    padding: 10px 10px;
    border: 1px #f7941d solid;
    border-radius: 5px;
    background: white;
    white-space: pre;
    color: black;
}

.wrapper .bloco-conteudo .informacoes-lead-wrapper .comentario-field-section .comentario-field[contenteditable]:empty::before {
    content: "Registre aqui, um comentário...";
    font-style: italic;
    color: #666;
  }


.wrapper .bloco-conteudo .informacoes-lead-wrapper .comentario-field-section .btn-comentario-field {
    background-color: var(--dommus-color-green);
    font-size: 1.2rem;
    color: white;
    border-radius: 5px;
    border: none;
    padding: 0px 8px;
    margin-right: 5px;
}


.wrapper .item-slide {
    width: 100%;
}

.rec-carousel {
    height: 475px !important
}

.pessoa {
    position: relative;
}

.editar {
    position: absolute;
    right: 0;
    cursor: pointer;
    font-size: 20px;
}

.rec-slider-container {
    height: 485px;
}

.wrapper .fotoEnvolvido {
    font-size: 95px;
    line-height: 1;
}

.wrapper .fotoEnvolvido svg {
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 50px;
}

.opv-lead-mobile .nav{
    justify-content: space-around;
    gap: 5px;
    border: none;
    margin-bottom: 15px;
}

.opv-lead-mobile .nav-link{
    border: 1px solid #666;
    flex-basis: 45%;
    color: #666;
    border-radius: 5px;
    text-align: center;
}

.opv-lead-mobile .nav-link.active{
    background-color: var(--dommus-color-2);
    color: white;
    font-weight: 600;
}
.opv-lead-mobile .leads-mobile-card{
    width: 100%;
}

.opv-lead-mobile .leads-wrapper .leads-navigation{
    flex-direction: row !important;
}

.opv-lead-mobile .leads-wrapper .leads-mobile-card .leads-navigation{
    flex-direction: row;
}
.opv-lead-mobile .midia{
    align-items: flex-start;
}
.opv-lead-mobile .midia svg{
    width: 2.5rem;

}
.opv-lead-mobile .midiaInfo{
    width: 78vw;
}

.opv-lead-mobile .infos{
    text-align: left;
}
.opv-lead-mobile .data-item-mobile{
    color: #666;
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.opv-lead-mobile .unificar-historico-leads-section{
    width: 100%;
}
.opv-lead-mobile .distribuicaoInterna-cadastro{
    margin-right: 0;
}

.leads-tablet {
    width: 100%;
}
.leads-tablet .opv-lead-mobile .leads-mobile-card{
    width: 100%;
}


.opv-lead-tablet .midiaInfo{
    width: 100% !important;
}
@media (max-width: 376px) {
    .midiaInfo .infos {
        font-size: calc(12px - 0.6667vw);
    }
}

@media (max-width:767px) {
    .styleIconNvLead {
        margin-left: 0% !important;
    }

    .conteudoPagina {
        height: 85vh !important;
    }

    .midiaInfo {
        width: 100%;
    }

    .wrapper .bloco-conteudo .informacoes-lead-wrapper .leads .leads-wrapper .leads-navigation>li a .info.nome,
    .wrapper .bloco-conteudo .informacoes-lead-wrapper .leads .leads-wrapper .leads-navigation>li a .info.email,
    .wrapper .bloco-conteudo .informacoes-lead-wrapper .leads .leads-wrapper .leads-navigation>li a .info.telefone,
    .wrapper .bloco-conteudo .informacoes-lead-wrapper .leads .leads-wrapper .leads-navigation>li a .info.empreendimento,
    .wrapper .bloco-conteudo .informacoes-lead-wrapper .leads .leads-wrapper .leads-navigation>li a .info.campanha {
        width: 60vw !important;
    }

    .content .pagination {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .content .form-group {
        width: 80vw;
    }

    .conteudoPagina .coluna {
        height: 0;
        max-width: 100%;
    }

    .dataItem {
        display: none;
    }

    .wrapper .bloco-conteudo .informacoes-lead-wrapper .leads .leads-wrapper .leads-content {
        display: none;
    }

    .midiaInfo .infos {
        overflow: hidden;

    }

    .wrapper .fotoEnvolvido {
        font-size: 75px;
    }

    .editar {
        font-size: 16px;
    }

    .informacoes-lead-wrapper .pessoas-envolvidas {
        width: 100%;
    }

    .bloco-conteudo .informacoes-lead-wrapper {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .wrapper .bloco-conteudo .informacoes-lead-wrapper .leads .leads-wrapper .leads-navigation>li a.active::after {
        content: none;
    }

    .wrapper .bloco-conteudo .informacoes-lead-wrapper .leads .leads-wrapper .leads-navigation>li a {
        border-radius: 5px;
        padding: 10px;
    }

    .wrapper .bloco-conteudo .informacoes-lead-wrapper .leads .leads-wrapper {
        display: flex;
    }

    .distribuicaoInterna .row {
        flex-direction: column;
        /* width: 85vw; */
    }

    .distribuicaoInterna {
        margin-right: 0;
    }

    .listaItens .midia-detalhes {
      width: 5% !important;
      font-size: 25px !important;
    }

    .coluna-lead .nav-link.active{
      height: 140px !important;
    }

    .coluna-lead{
      padding-right: 0px !important;
      padding-left: 10px !important;
    }
}

.styleIconNvLead {
    margin-right: 10px;
}

#dropdownMenu {
    background-color: transparent;
    color: black;
    border: none;
  }

#dropdownMenu::after{
    display: none;
}

#dropdownMenu .btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus{
  box-shadow: none;
}
#dropdownMenu .btn-primary.focus, .btn-primary:focus {
    box-shadow: none;
}

.coluna-lead {
    margin-right: 10px;
    max-width: 100%;
}

.coluna-lead .nav-link{
    display: flex;
    flex-direction: row;
    position: relative;
    margin-right: 10px;
}

.coluna-lead .item-edicao-lead{
    position: absolute;
    top: 0;
    right: 30px;
}

.info-adicionais-lead{
    position: absolute;
    top: 6px;
    right: 60px;
}

.menuLead {
    position: "absolute";
    padding-top: 5;
    margin-right: 2;
    right: 0;

}

.unificar-historico-leads-section{
    display: flex;
    width: 98%;
    padding: 0px 10px;
    margin-bottom: 10px;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 5px;
}
.unificar-historico-leads-section label{
    margin-top: 8px;
    cursor: pointer;
}

.unificar-historicos-leads{
    height: 10px;
    cursor: pointer;
}

.cadastro-tags-section{
    display: flex;
    flex-direction: row;
    padding: 10px;
    background-color: white;
    border-radius: 10px;
    margin-bottom: 10px;
}

.cadastro-tags-section legend{
    font-size: 14px;
    color: #666;
    margin-bottom: 0;
}

.cadastro-tags-section .tags-space{
    flex-basis: 95%;
}

.cadastro-tags-section .tags-space .tag-section{
    flex-wrap: wrap;
    row-gap: 5px;
    justify-content: flex-start;
}

.button-tags{
    height: 25px;
    width: 25px;
    background-color: #f1a042;
    justify-items: end;
    color: white;
    font-size: 11px;
    padding: 3px 5px;
    border-radius: 3px;
    margin-left: 4px;
    font-weight: 500;
    cursor: pointer;
    border: none;
}

.alert-etiquetas{
    margin-bottom: 0 !important;
    padding: 0px 10px !important;
    font-size: "12px" !important;
}
