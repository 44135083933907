.perfil {
    justify-content: center;
}
.item-barra#perfil {
    background-color: #3E6780 !important;
    color: #fff;
    font-size: 25px !important;
    text-align: center;
    max-height: 14rem;
    line-height: 19rem;
}
.h6.info {
    position: absolute;
    top: 12.2rem;
    left: 42.2%;
    color: #fff;
    border-bottom: #F7941D solid 5px;
    padding-bottom: 5px;
    font-weight: bold;
}
.form-perfil {
    background-color: #fff;
    padding-top: 2rem;
    min-height: 18rem;
    height: 100rem;
}

.tab-meu-perfil a.nav-item.nav-link{
  color: white !important;
}


#img-perfil > .picture-topo img {
    position: absolute !important;
    top: 2.2rem !important;
    left: 47% !important;
    width: 5rem !important;
    height: 5rem !important;
}
@media (max-width: 768px)
{
    .h6.info {
        position: absolute;
        top: 12.2rem;
        left: 36%;
    }
    #img-perfil > .picture-topo img  {
        position: absolute !important;
        top: 2rem !important;
        left: 45% !important;
        width: 5rem !important;
        height: 5rem !important;
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    outline: none;
  }
  .switch input {
    position: absolute;
    top: -99999px;
    left: -99999px;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }
  input:checked + .slider {
    background-color: #2196F3;
  }
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }