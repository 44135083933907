h2{
    font-size: unset;
    margin: 0;
}

.formCadastro label{
    font-weight: bold;
    color: #696969;
}

.formCadastro .row {
    border-bottom: unset;
}

.formCadastro .form-group{
    padding-top: 10px;
}
.formCadastro .lastRow{
    border-bottom: 0;
}
.formCadastro .formFooter{
    border-top: 1px solid #cccc;
    border-bottom: 0;
    padding-top: 10px;
}
.formCadastro .formFooter .col{
    justify-content: flex-end;
    align-items: center;
    display: flex;
}
.formCadastro .items-form{
    padding: 0px 10px;
    max-height: 75vh;
    overflow-y: auto;
    overflow-x: hidden;
}