.dropdown-heading-value > span {
    color: #000;
}

.font-size-lg {
    font-size: 16px;
    color: #ccc;
}

.checkbox-toggle {
    margin-top: 13px;
}

.col-tarefa {
    padding-top: 10px;
}

.col-separator {
    background-color: #343A40;
    width: 5px!important;
    height: 6rem;
    display: flex;
    flex-direction: row-reverse;
}

.footer-form-atendimento{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    

}

@media (max-width:767px) {
    .footer-form-atendimento{
        padding-top: 20px;
        padding-bottom: 60px;
    }
}