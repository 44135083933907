.spaceData{
    /* padding-left: 8vh; */
}
.colorBtn{
    border-color: var(--dommus-color);
    background-color: var(--dommus-color);
    z-index: 0!important;
}

.colorBtn:hover{
    background-color: var(--dommus-color);
}

.colorBtn:focus{
    border-color: var(--dommus-color);
    background-color: var(--dommus-color);
}

.colorBtn:disabled{
    border-color: var(--dommus-color);
    background-color: var(--dommus-color);
}

.formCadastro fieldset{
    border-radius: 5px;
    padding: 5px 15px;
    width: 100%;
}

.formCadastro .container-filtro{
    padding: 10px 10px 10px 0px;
    margin: 0px 5px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 65vh;
    scroll-behavior: smooth;
}

.footer-filtro{
    border-top: 1px solid #ddd;
    padding-top: 15px;
}

.btn-filtro-acao{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
}

@media (max-width: 767px){
    .footer-filtro{
        padding-bottom: 50px;
    }
    .btn-filtro-acao{
        margin-top: 10px;
    }
    .agrupar-opv{
        justify-content: center;
    }
}
