.pergunta-card{
    padding: 10px;
    margin: 15px 1px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background: white;
}

.pergunta-card .info{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.pergunta-card .info .enunciado-section{
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.pergunta-card .info .tipo-resposta{
    color: #666;
    /* margin-top: px; */
}

.pergunta-card .info .respostas-section{
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    height: 100%;
}

.pergunta-card .acao-section .dropdown-toggle::after{
    display: none !important;
}

.btns-acao-pergunta-section{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}

.btns-acao-pergunta-section .btn-toolbar{
    width: 30%;
}

.btns-acao-pergunta-section .btn-group-toggle{
    width: -webkit-fill-available;
    height: 40px;
}

.btns-acao-pergunta-section .btn-danger{
    width: 40px;
    height: 40px;
}

.btns-acao-pergunta-section .form-switch{
    display: flex;
    align-items: center;
    justify-content: center;
}