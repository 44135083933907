.btn-group>.btn-group:not(:first-child)>.btn, .btn-group>.btn:not(:first-child):not(.dropdown-toggle) input[type=radio] {
    display: none;
}

.top-group-radio-historico .btn-group>.btn-group:not(:first-child)>.btn, .btn-group>.btn:not(:first-child):not(.dropdown-toggle) {
    width: 20vh !important;
}

.btn-group>.btn-group:not(:last-child)>.btn, .btn-group>.btn:not(:last-child):not(.dropdown-toggle) input[type=radio] {
    display: none;
}

.btn-group>.btn-group:not(:first-child)>.btn, .btn-group>.btn:not(:first-child):not(.dropdown-toggle) {
    width: 40vh;
}

.btn-group>.btn-group:not(:last-child)>.btn, .btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
    width: 40vh;

}

.top-group-radio-historico .btn-group>.btn-group:not(:last-child)>.btn, .btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
    width: 20vh;
}

.top-group-radio .btn-outline-warning:not(:disabled):not(.disabled).active, .btn-outline-warning:not(:disabled):not(.disabled):active, .show>.btn-outline-warning.dropdown-toggle {
    color: #fff !important;
    background-color: #666;
    border-color: #666;
    width: 14vw;
}

.top-group-radio .btn-outline-warning {
    color: #aaa;
    border-color: #aaa;
}

.top-group-radio .btn-outline-warning:hover {
    color: #fff !important;
    background-color: #aaa;
    border-color: #aaa;
}

.top-group-radio {
    width: 100%;
    display: flex;
    justify-content: center;
}