body{
    overflow-x: hidden;
}
.conteudoPagina{
    overflow: auto ;
    margin: 0 auto;
    white-space: nowrap;
    display: block !important;
    height: 76vh;
}

.coluna{
    margin-right: 20px;
    width: 355px;
    display: inline-grid;
}
.coluna .status{
    background-color: #bae503;
    height: 33px;
    color: #fff;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    font-size: smaller;
}
.listaItens{
    height: 65vh;
    overflow: auto;
    overflow-x: hidden;
}
.listaItens .linha{
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1;
    min-height: 18px;
}

.listaItens .linha .codigo{
    color: #f44336;
}

.listaItens .linha h1{
    color: #333333;
    font-size: 13px;
    font-weight: bold;
    padding: 0px;
    text-transform: uppercase;
    line-height: 1;
    margin: 0;
}

.linha .texto{
    font-size: 12px;
    letter-spacing: normal;
    font-weight: normal;
    padding: 5px 0;
    color: #999 !important;
}

.listaItens .card{
    width: 336px;
    background-color: #fff;
    margin-top: 10px;
    border-radius: 8px;
    border-left: 5px solid #bbb;
    border-bottom: 1px solid #bbb;
    border-top: 1px solid #bbb;
}

.listaItens .cardTopo{
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    align-items: center;
    height: 95px;
    min-height: 95px;
    padding-left: 0;
    cursor: pointer;
}
.listaItens .cardBaixo{
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.cardBaixo .data{
    font-size: 11px !important;
    display: flex;
    align-items: center;
}
.cardBaixo .data svg{
    margin-right: 10px;
}
.listaItens .icones{
    display: flex;
    justify-content: flex-end;
    width: 150px;
    align-items: center;
}
.listaItens .icones svg:not(:last-child){
    margin-right: 10px;
}

.listaItens .conteudoItem{
    width: 75%;
    padding: 0px;

}

.listaItens .midia{
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
}
.listaItens .card button{
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: unset !important;
    line-height: 1;
    width: 5px;
    padding: 0;
    margin: 0;
    border: 0;
    display: flex;
    justify-content: center;
}
.listaItens .card .dropdown-toggle::after{
display: none;
}
.listaItens .card .novolead {
    padding: 3px;
    width: 18px;
    height: 18px;
    border: 1px solid orange;
    border-radius: 59%;
    text-align: center;
    display: flex;
    justify-content: center;
    font-size: 10px;
    font-weight: bold;
  }

  .listaItens .card .star::before {
    display: inline-block;
    height: 100%;
    background: blue;
    vertical-align: middle;
    content: '';
  }
.buttonNovaOportunidade{
    background-color: #183b64;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 30px;
    bottom: 30px;
    padding: 15px;
    font-size: 35px;
    border-radius: 100%;
    cursor: pointer;
}
.btnVisualizacao button{
    background-color: unset !important;
    color: #fff !important;
    outline: none !important;
    border: 0;
}
.btnVisualizacao button::after{
    display: none;
}
.btnVisualizacao button:hover, .btnVisualizacao button:focus{
    background-color: unset !important;
    color: #fff !important;
    outline: none !important;
    box-shadow: none !important;
}
.badgeEtapa{
    border: 1px solid #ddd;
    padding: 2px;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    align-self: center;
    display: flex;
    text-align: center;
    color: #fff;
    font-weight: 600;
    width: 100%;
}

.badgeTempo{
    align-items: center;
    display: flex;
    justify-content: center;
}

.badgeTempo .data svg{
    margin-right: 5px;
}

.icone-ordem{
    font-size: 18px;        
}

.new-dashboard .form-switch{
    margin-top: 10px;
    margin-left: 12px;
}

.btn-historico-distribuidos{
    background: #fe9500 !important;   
    border: 1px solid #fe9500; 
    cursor: pointer;
}

.btn-historico-distribuidos svg{
    color: white;
}

.btn-historico-distribuidos:hover{
    background: white !important;    
    color: #fe9500 !important; 
    border: 1px solid #fe9500; 
}

.btn-historico-distribuidos:hover svg{
    color: #fe9500 !important; 
}

@media (max-width:321px){
    .buttonNovaOportunidade .btn.btn-primary{
        right: 8%;
    }
    .buttonNovaOportunidade{
        right: 8%;
    }
}
@media (max-width:600px){
    .buttonNovaOportunidade .btn.btn-primary{
        right: 8%;
    }
    .buttonNovaOportunidade{
        right: 8%;
    }
}
@media (max-width:375px){
    .buttonNovaOportunidade .btn.btn-primary{
        right: 7%;
    }
    .buttonNovaOportunidade{
        right: 7%;
    }
}

@media (max-width:360px){
  .buttonNovaOportunidade .btn.btn-primary{
    margin-right: 34% !important;
    margin-left: 35%;
  }
  .buttonNovaOportunidade{
    margin-right: 34% !important;
  }
}

@media (max-width:767px) {
    .listaItens .card{
        width: 98%;
    }
    .buttonNovaOportunidadeMobile{
        background-color: #183b64;
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px;
        font-size: 35px;
        border-radius: 100%;
        cursor: pointer;
    }
    .coluna .status{
        width: 98%;
        font-size: 13px;
    }
    .buttonNovaOportunidade .btn.btn-primary{
      display: flex;
      margin-left: 35%;
      margin-right: 35%;
      bottom: 5px;
    }
    .buttonNovaOportunidade{
      margin-right: 35%;
      margin-left: 35%;
      bottom: 5px;
      z-index: 22;
    }
    #atendimentoMobile{
      z-index: 22;
      bottom: 5px;
    }

    .btnVisualiza{
        cursor: pointer;
    }
}