/* PESQUISA / FILTRO */
.search-wrapper{
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    height: 93%;
    align-items: start;
    justify-content: center;
    background-color: rgba(0,0,0,.5);
    z-index: 99;
    overflow: auto;
    font-family: 'Poppins', Arial, Helvetica, sans-serif !important;
    font-size: 14px;
}
.search-wrapper .search-content{
    width: 100%;
    max-width: 100%;
    display: flex;
    align-items: flex-start;
    /*justify-content: center;*/
    flex-direction: column;
    background-color: #fff;
    min-height: 40%;
}
.search-wrapper .search-content .search-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-transform: uppercase;
    padding: 0px 15px;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
}
.search-wrapper .search-content .search-title h3{
    margin-bottom: 0;
    font-weight: 700;
    color: #000;
    font-size: 14px;
}
.search-wrapper .search-content .search-title h3:hover{
    color: var(--dommus-color-2);
    cursor: help;
}
.search-wrapper .search-content .search-title .item-ativos{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.search-wrapper .search-content .search-title .filter-right-nav{
    display: flex;
    align-items: center;
}
.search-wrapper .search-content .search-title .close-search{
    color: #000;
}
.search-wrapper .search-content .search-title .close-search:hover{
    cursor: pointer;
}
.search-wrapper .search-content .search-title .close-search picture img{
    width: 20px;
}
.filter-wrapper{
    padding: 15px;
    width: 100%;
}

.filter-wrapper form label{
    font-size: 0.7rem;
    font-weight: 700;
    color: #000;
}
.filter-wrapper .form-control, .filter-wrapper select{
    height: 40px!important;
}
.filter-wrapper form .form-group .form-control{
    font-size: 14px;
}

.filter-wrapper .btnFiltroWrapper:hover{
    background-color: var(--dommus-color-2);
}
.filter-wrapper .btnFiltroWrapper i{
    margin-right: 10px;
}

@media (min-width:992px) {
    .search-wrapper {
        top: 75px;
    }
}

@media (max-width:767px) {
    .search-wrapper{
        overflow: auto;
        z-index: 999;
    }
}

@media (max-width:425px) {
    .search-wrapper .search-content .search-title{
        flex-direction: row !important;
        justify-content: space-between;
        padding: 0;
    }
    .search-wrapper .search-content .search-title h3{
        padding: 0 15px;
        height: 50px;
        display: flex;
        align-items: center;
    }
    .search-wrapper .search-content .search-title .right-nav{
        justify-content: space-between;
        flex-direction: column;
    }
    .search-wrapper .search-content .search-title ul li:first-child{
        margin-left: 0;
    }
    .search-wrapper .search-content .search-title .right-nav .close-search{
        position: absolute;
        top: 12.5px;
        right: 15px;
    }
    .search-wrapper .search-content .search-title .item-ativos{
        padding: 15px;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        width: 100%;
        justify-content: flex-start;
    }
    .search-wrapper .search-content .search-title .item-ativos .custom-control:first-child{
        margin-right: 15px;
    }
    .search-wrapper .search-content .search-title ul{
        align-items: center;
        justify-content: space-around;
        width: 100%;
        border: 0;
        padding: 15px;
        margin: 0;
        border-top: 1px solid #ddd;
    }
    .filter-wrapper{
        padding: 0;
    }
}

.btnAltMassa{
    width: 230px !important;
}

.form-first-row {
    padding-top: 10px!important;
}

.css-26l3qy-menu {
    color: #000!important;
}


