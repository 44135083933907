h2{
    font-size: unset;
    margin: 0;
}
.formCadastro{

}

.formCadastro label{
    color: #868aa8 !important;
    font-size: 12px;
    font-weight: 400 !important;
    margin-bottom: 0;
}

.formCadastro .form-control:focus{
    box-shadow: 0 2px 0 0 #183b64;
    border-color: #183b64;
}

/*.formCadastro .row {*/
/*    border-bottom: 1px solid #cccc;*/
/*}*/

.formCadastro .form-group{
    padding-top: 0;
    margin-bottom: 1rem;
}
.formCadastro .lastRow{
    border-bottom: 0;
}
.formCadastro .formFooter{
    border-top: 1px solid #cccc;
    border-bottom: 0;
    padding-top: 10px;
}
.formCadastro .formFooter .col{
    justify-content: flex-end;
    align-items: center;
    display: flex;
}
.formCadastro .distribuicaoInterna {
    margin-right: 0px;
}

.checkbox-campos-travados{
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    padding: 12px;
}

.checkbox-campos-travados .form-check label{
    cursor: pointer;
    font-size: 0.9rem;
}

.items-campos-travados-section{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.swal2-actions{
    gap: 10px;
}

.campo-travados-oculto{
    display: none;
}

.btn-action-sweet-criar-opv{
    display: flex;
    flex-direction: row;
}

.btn-abrir-oportunidade{
    background-color: var(--dommus-color-2);
    color: white;
}

.btn-abrir-oportunidade:hover{
    background-color: #db7f10;
    color: white;
}

@media (max-width:767px) {
    .items-campos-travados-section{
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 10px;
    }
}