.btAdicionarLinha{
    display: flex;
    justify-content: flex-end;
}


.substatus-section .dommus-toggle{
    border-left: 5px solid  var(--dommus-color) ;
}

.substatus-item .list-group-item{
    border-left: 5px solid #5d7bd5;
    margin-bottom: 5px;   
    border-radius: 5px; 
}