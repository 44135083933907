.dommus-menu-mobile{
  position: fixed;
  width: 100%;
  z-index: 8;
  height: 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  background-color: #fff;
  box-shadow: 0 -2px 4px rgba(0,0,0,0.1);
  color: #fff;
  display: none;
}

.dommus-menu-mobile .logo{
  height: 75px;
  display: flex;
  align-items: center;
}

.dommus-menu-mobile .logo picture {
  display: flex;
  align-items: center;
  height: 100%;
}
.dommus-menu-mobile .online{
  margin-right: 20px;
}

.dommus-menu-mobile .logo img{
  width: 180px;
  max-width: fit-content;
}

.dommus-menu-mobile .right-nav {
  display: flex;
  height: 75px;
}

.dommus-menu-mobile .info-nav{
  font-size: 30px;
  height: 75px;
  align-items: center;
  display: inline-flex;
}
.dommus-menu-mobile .info-nav .picture-topo img{
  width: 25px;
}
.dommus-menu-mobile .info-nav .barsMenuMobile{
display: none;
}

.dommus-menu-mobile .info-nav  .picture-topo{
  cursor: pointer;
  width: 30px;
  margin-right: 20px;
  position: relative;
  display: flex;
  align-items: center;
}

.dommus-menu-mobile .right-nav .info-nav > div {
  margin-right: 20px;
  display: inline-flex;
}
.dommus-menu-mobile .right-nav .modulo-name {
  font-weight: 700;
  color: #fff;
  font-size: 16px;
  line-height: unset;
  padding: 0;
  margin: 0;
  margin-right: 25px;
}
.dommus-menu-mobile .right-nav .user-name {
  color: #fff;
  font-size: 15px;
  line-height: unset;
  padding: 0;
  margin: 0px 5px;

}
.dommus-menu-mobile .online{
  display: flex;
  align-items: center;
}
.dommus-mobile-navigation{
  display: none;
}

@media (max-width:767px) {
  .dommus-menu-mobile .info-nav  .picture-topo{
      margin-right: 13px;
  }
  .dommus-menu-mobile .right-nav{
      margin-left: 5px;
  }
  .dommus-menu-mobile{
      height: 55px;
      display: flex;
      position: fixed;
      bottom: 0;
      padding: 0 10px;
      width: 100%;
      left: 0;
  }
  .dommus-menu-mobile .navigation{
    display: flex;
    padding: 0;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    list-style: none;
    margin-bottom: 0;
  }
  .dommus-menu-mobile .navigation .meio-footer-mobile{
    position: absolute;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -15px;
    background-color: #183b64;
    border-radius: 50%;
    border: 2px solid gainsboro;
    -webkit-box-shadow: 0px 14px 16px -3px rgba(0,0,0,0.40);
    -moz-box-shadow: 0px 14px 16px -3px rgba(0,0,0,0.40);
    box-shadow: 0px 14px 16px -3px rgba(0,0,0,0.40);
  }
  .dommus-menu-mobile .navigation .meio-footer-mobile svg{
    width: 50%;
    height: 40%;
  }
  .dommus-menu-mobile .navigation .meio-footer-mobile::after{
    display: none !important;
  }
  .dommus-menu-mobile .navigation li:nth-child(2){
    margin-right: 70px;
  }
  .dommus-menu-mobile .navigation .navigation-menu-footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
    bottom: 2px;
  }
  .dommus-menu-mobile .navigation .navigation-menu-footer svg{
    width: 24px;
    height: 24px;
  }
  /* .dommus-menu-mobile .navigation .navigation-menu-footer:first-child svg{
    width: 24px;
  }
  .dommus-menu-mobile .navigation .navigation-menu-footer:nth-child(2) svg{
    width: 24px;
  } */
  .dommus-menu-mobile .navigation .navigation-menu-footer span{
    font-size: 10px;
    font-weight: 700;
    color: #666;
  }

  .dommus-menu-mobile .buttonNovaOportunidade .btn.btn-primary{
    margin-right: 35%;
    margin-left: 35%;
    bottom: 5px;
  }
  .dommus-menu-mobile .logo img{
      width: 130px;
      max-width: 150%;
  }
  .dommus-menu-mobile .onlineFlag{
    display: none;
  }
  .dommus-menu-mobile .info-nav  .picture-topo .onlineFlag{
      display: none;
  }
  .dommus-menu-mobile .info-nav  .picture-topo .offlineFlag{
      display: none;
  }
  .dommus-mobile-navigation{
      display: block;
  }
  .dommus-menu-mobile .info-nav .barsMenuMobile{
    display: flex;
  }
}
.dommus-menu-mobile .perfil-dropdown {
  background: none;
  display: flex;
  border: none;
}
.dommus-menu-mobile .perfil-dropdown h1.user-name {
  margin-top: 5px;
}
.dommus-menu-mobile .perfil-dropdown::after {
  content: none;
}

.perfil-dropdown.btn-primary:not(:disabled):not(.disabled).active,
.perfil-dropdown.btn-primary:not(:disabled):not(.disabled):active,
.perfil-dropdown.show>.btn-primary.dropdown-toggle,
.perfil-dropdown.show>.btn-primary.dropdown-toggle:focus,
.perfil-dropdown.btn-primary.focus,
.perfil-dropdown.btn-primary:focus{
  background: none !important;
  border: none !important;
  box-shadow: none !important;
}
