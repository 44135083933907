.tabela-dommus thead{
  font-size: 12px;
}

#listaVisitas .table td, .table th{
  padding: 5px 5px;
}
.cardLista{
  background-color: #fff;
  padding: 5px;
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  border: 1px solid #ddd
}

.buttonCustomizado{
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
}

.acessar{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.form-check .form-check-input position-static{
  margin-right: 0px !important;
}

.form-check .form-check-input input[type=checkbox]{
  margin-right: 1px !important;
}

.rbc-toolbar{
  z-index: 999;
  width: 94%;
}
