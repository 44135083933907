.titulo-fieldset-checklist {
    font-size: 10pt;
    font-weight: bold;
    margin-bottom: 0px;
  }

.caixa-fieldset-checklist {
  margin-top: 5px;
  padding: 10px;
  background-color: transparent;
  border: 1px solid #999;
}
  
.secao-inativos{
  border-radius: 10px;
  padding: 5px 10px;
}

.secao-inativos .multiselect-qualificacao .dropdown-heading{
  background-color: #e9ecef;
}

.formCadastro .form-group{
  width: 100%;
}