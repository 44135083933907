.documentacao-item-wrapper h6 {
    padding: 15px;
    padding-bottom: 0;
    font-size: 14px;
    font-weight: 700;
}

.documentacao-item-wrapper {
    border: 1px solid #ddd;
    border-radius: 0 5px 0 0;
    background-color: #f2f3f8;
    margin-bottom: 10px;
}
.documentacao-item-wrapper .upload-wrapper {
    margin: 15px;
    background-color: #fff;
    margin-top: 0;
    justify-content: space-between;
}
.upload-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px dashed #c0bfbf;
    border-radius: var(--dommus-b-radius);
    transition: var(--smooth);
}
.documento-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
}
.documentacao-item-wrapper .ajax-file-upload-container {
    display: flex;
    width: 100%;
}
.documentacao-item-wrapper .ajax-file-upload-container a {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    font-weight: 700;
    width: 100%;
    color: #353252;
}
.documentacao-item-wrapper .status {
    position: relative;
    background-color: unset;
    height: unset;
    color: unset;
    font-weight: unset;
    display: unset;
    padding: unset;
    justify-content: unset;
    align-items: unset;
    border-radius: unset;
    cursor: unset;
}
.status img {
    width: 35px;
    height: 35px;
}
.documentacao-item-wrapper .ajax-file-upload-container a span {
    font-size: 10px;
    color: #868aa8;
    font-weight: 400;
}
.documentacao-item-wrapper .topoArquivo{
    display: flex;
    justify-content: space-between;
}

.documentacao-item-wrapper .topoArquivo .dropdown{
    align-items: center;
    display: flex;
}

.documentacao-item-wrapper .topoArquivo button{
    background-color: unset;
    border-color: unset;
    padding: 0;
    outline: none;
    border: 0;
    margin-right: 10px;
}
.documentacao-item-wrapper .topoArquivo button::after{
    display: none;
}
.documentacao-item-wrapper .topoArquivo button:active {
    color: unset !important;
    background-color: unset !important;
    border-color: unset !important;
}