.user-agenda{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 20px;
}

.user-agenda-calendar{
  display: flex;
  height: 500px;
  width: 40%;
}

.user-agenda-calendar .react-calendar{
  max-height: 55%;
  width: 92%;
  border: none;
  border-radius: 10px;
}

.user-agenda-calendar .react-calendar__navigation{
  background-color: #f2f3f8;
  margin-bottom: 5px;
}

.user-agenda-calendar .react-calendar__viewContainer, .user-agenda-calendar .react-calendar__month-view
, .user-agenda-calendar .react-calendar__month-view div{
  height: 100%;
}

.user-agenda-calendar .react-calendar__month-view__weekdays{
  height: auto !important;
}

.user-agenda-calendar .react-calendar__navigation button{
  border: 3px solid #fff;
  border-bottom: none;
}

.user-agenda-calendar .react-calendar__month-view__weekdays__weekday{
  border: 3px solid #fff;
  background-color: #666;
  color: #fff;
  border-radius: 10px;
}

.user-agenda-calendar .react-calendar__month-view__days__day{
  background-color: #f2f3f8;
  border-radius: 10px;
  border: 3px solid #fff;
  height: 60px;
}

.user-agenda-calendar .react-calendar__month-view__days__day--neighboringMonth{
  background-color: #fff;
}

.user-agenda-calendar .react-calendar__tile--active{
  background-color: #2e8bb0;
}

.user-agenda-calendar .react-calendar__tile--active:hover{
  background-color: #2e8bb0;
  background: #2e8bb0;
  opacity: 0.7;
}

.user-agenda-calendar .react-calendar__tile--active:focus{
  background-color: #2e8bb0;
  background: #2e8bb0;
}

.user-agenda-event-list{
  width: 60%;
}

.user-agenda-next-events{
  font-weight: bold;
  padding: 10px;
  background-color: #666;
  color: #fff;
  display: flex;
  justify-content: space-between;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.user-agenda-arrow{
  line-height: 1;
  font-size: 23px;
  font-weight: normal;
  cursor: pointer;
}

.user-agenda-header{
  width: 100%;
  display: flex;
  flex-direction: row;
  font-weight: bold;
  background-color: #f2f3f8;
}

.user-agenda-event-item{
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 3px 0px;
  border-bottom: 1px solid #ccc;
}

.user-agenda-event-item-date{
  border: 3px solid #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 5px;
  width: 20%;
}

.user-agenda-event-item-data{
  display: flex;
  border: 3px solid #fff;
  flex-direction: column;
  padding: 5px;
  width: 60%;
  max-height: 70px;
  font-size: 13px;
  overflow: hidden;
  transition-timing-function: ease;
  transition-property: max-height;
  transition-duration: 1s;
}



.user-agenda-event-item-data:hover{

  max-height: 300px;
}

.user-agenda-event-item-actions{
  border: 3px solid #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20%;
}

.event-in-calendar{
  display: flex;
  width: 100%;
  background-color: #2e8bb0;
  height: 2px;
  margin-top: 5px;
}

.events {
  height: 45rem;
  overflow-y: scroll;
}

.caixa-visita {
  margin-top: 5px;
  padding: 10px;
  background-color: #f5f5f5;
  border: 1px dotted #999;
}

.titulo-fieldset {
  font-size: 10pt;
  font-weight: bold;
  margin-bottom: 0px;
}

.calendario-mobile {
  margin-top: 20px;
}

.calendario-mobile .nav{
  justify-content: space-around;
  gap: 5px;
  border: none;
  margin-bottom: 15px;
}

.calendario-mobile .nav-link{
  border: 1px solid #666;
  flex-basis: 45%;
  color: #666;
  border-radius: 5px;
  text-align: center;
}

.calendario-mobile .nav-link.active{
  background-color: var(--dommus-color-2);
  color: white;
  font-weight: 600;
}

@media (max-width:767px){
  .user-agenda-calendar{
    width: 100%;
  }
  .user-agenda-calendar .react-calendar{
    width: 100%;
  }

  .user-agenda-event-list{
    width: 100%;
  }
}