.btAdicionarDesistencia{
    display: flex;
    justify-content: flex-end;
}

.inputStyle {
    border:  none; 
    width: 100%;
    background-color: transparent; 

}

.inputStyle:hover {
    width: 100%;
    padding: 0.5rem 0 0.5rem 0.5rem;
    background-color: rgb(255, 255, 255); 
}

.inputStyle:focus {
    width: 100%;
    padding: 0.5rem 0 0.5rem 0.5rem;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid rgb(170, 168, 168);
    border-radius: 4px;
    box-sizing: border-box;

}
