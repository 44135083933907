.btn-remove-section{
    display: flex;
    justify-content: end;
    align-items: center;
}

.multiselect-qualificacao{
    color: black;
}

.remover-bloco {
    border: none;
    background: rgb(224, 93, 93);
    display: flex;
    height: 38px;
    border-radius: 5px;
    margin-top: 5px;
    margin-right: 15px;
    padding: 0px 15px;
    align-items: center;
    justify-content: center;
}
.remover-bloco svg{
    color: white;
    font-size: 18px;    
}