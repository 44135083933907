.dados-lead-lista{

}
.dados-lead-lista:hover{
    text-decoration: underline;
}

.loading-leads {
    text-align: center;
    border: 1px black solid;
    border-radius: 10px;
    top: -20px;
    position: relative;
    background-color: white;
}

.lead-unificado{
    display: flex;
    padding-top: 3px;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    width: 18px;
    height: 18px;
    border: 1px solid orange;
    font-size: 10px;
    font-weight: 700;
    margin-left: 10px;
}

.funilOportunidadeOpcoes {
    display: flex;
    justify-content: center;
}

.funilOportunidadeOpcoes button{
    padding: 10px;
}

.header-table-item{
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 2px;
    align-items: center;
}

.section-table-oportunidade{
    height: calc(100vh - 170px);    
    overflow-y: auto;
    overflow-x: hidden;
}