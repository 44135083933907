.areaAtrativos .card-body {
    cursor: default !important;
}

.buttonEditarAtrativos{
    cursor: pointer;
    z-index: 1;
    margin-top: -3.2rem;
    margin-right: 40px;
}
.linhaBotaoEditarAtrativos{
    justify-content: flex-end;
}
/* .modal-lable {
    width: 40px;
    display: inline-block;
} */

.atrativosLista .icon {
    text-align: center;
    /* color: black; */
}

.atrativosLista .title {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px ;
    text-align: center;
    /* color: black; */
}

.atrativosModal {
    text-align: center;
    overflow-y: scroll;
    height: 20rem;
    z-index: 1;
}

.salvarAtrativos{
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    padding-left: 2rem;
    padding-right: 2rem;
}

.atrativosLista .cardAtrativo {
    max-width: 50px;
    min-width: 7.5rem !important;
    max-height: 8rem;
    height: 8rem;
}

.atrativosSelecionados{
    display: contents;
}

.semAtrativos{
    margin-top: 30px;
}
/* .atrativosLista .atrativoCorpo{
      background-color: var(--dommus-color) ;
  }
  .atrativosLista .atrativoCorpo:active{
      background-color: var(--dommus-color-green) ;
  } */

.atrativosCard {
    display: contents;
    border: 2px solid black;
}
.atrativosCardlist {
    text-align: center;
    margin-top: -2rem;
}

.atrativosCardlist label {
    
    position: relative;
    margin: 10px;
    cursor: pointer;
}

.atrativosCard input[type="checkbox"] {
    display: none;
}

.atrativosCard label {
    /* border: 1px solid rgb(214, 7, 7);
    padding: 10px;
    display: block; */
    position: relative;
    margin: 10px;
    cursor: pointer;
    /* -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none; */
    user-select: none;
}

.atrativosCard label::before {
    background-color: white;
    color: white;
    content: " ";
    display: block;
    border-radius: 50%;
    border: 1px solid var(--dommus-color-green);
    position: absolute;
    top: 5px;
    left: -5px;
    width: 25px;
    height: 25px;
    text-align: center;
    /* line-height: 28px; */
    transition-duration: 0.4s;
    transform: scale(0);
}

label .atrativosLista {
    transition-duration: 0.2s;
    transform-origin: 50% 50%;
}

.atrativosCard :checked+label {
    border-color: #ddd;
}

.atrativosCard :checked+label::before {
    content: "✓";
    background-color: var(--dommus-color-green);
    transform: scale(1);
    z-index: 1;
}

.atrativosCard :checked+label .atrativosLista {
    transform: scale(0.9);
    box-shadow: 0 0 5px var(--dommus-color-green);
    z-index: -1;
}

.btn-remover{
    border-radius: 50%;
    position: absolute;
    margin-left: 75%;
    margin-top: 5px;
    width: 25px;
    height: 25px;
    background-color: #fff;
    border: #fff;
}

.dFFQiB{
    left: 95% !important;
    right: 0;
}

.kaHnvc{
 right: 95% !important;
 left:unset;
 /* left: -11px; */
}

.btn-remover:hover{
    background-color: #dc3545;
    border: #dc3545;
}

.btnCarrousel{
    background-color: var(--dommus-color) !important;
    color: #fff !important;
}