.dommus-icon {
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    line-height: 1;
    padding: 15px;
    font-size: unset;
    text-align: center;
    height: fit-content;
}

.dommus-icon:hover {
    opacity: 0.7;
}

.dommus-icon-list {
    display: flex;
    justify-content: space-between;
}

.dommus-icon-list .dommus-icon {
    margin: 0 5px;
}
