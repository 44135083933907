.section-btn{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 8px;
}

.section-btn label{
    font-weight: 600;
}

.envio-envolvido-section{
    display: flex;
    flex-direction: column;
}
.envio-envolvido-section button{
    margin: 0px 15px;
}
.envio-envolvido-section label{
    font-size: 0.9rem;
    color: grey;
}




















