.integracao_pagina
    .card-img{
        max-width:200px;
        max-height:50px;
        width: auto;
        height: auto;
        
    }
 
.integracao_pagina
    .integracao-card-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    }

.integracao_pagina
    .card{
        display: flex;
        text-align: center;
        cursor: auto;
    }
.integracao_pagina   
    .card-header{
        background-color: #fff;
        font-weight: bold;
    }
.integracao_pagina 
    .card-body{
        background-color: #fff;
    }
    .integracao_pagina 
    .card-title{
    text-align: center;
} 

.IntegrarD {
    pointer-events: none;
    opacity: 0.5;
    background-color: #CCC;
    border: none;
    color: white;
    text-align: center;
    font-size: 16px;
    border: #0069D9;
    opacity: 0.9;
    border-radius: 5px !important; 
    
  }
.Integrar{
    background-color: #0069D9;
    border: none;
    color: white;
    text-align: center;
    font-size: 16px;
    border: #0069D9;
    opacity: 0.9;
    border-radius: 5px !important; 
    
  }
.Integrar:focus{
    outline: none;
}
.Integrar:hover{
    color: white;
    text-align: center;
    font-size: 16px;
    opacity: 1;
  }

  .img-card,
  .img-card-2{
    object-fit: cover;
    object-position: center;
  }

  .img-card{
    max-width: 7rem;
   
  }

  .img-card-2{
    max-width: 3rem;
  }

.cardIntegracao{
    width: 20rem;
}


  @media screen and (max-width:1106px) {
    
    .img-card{
        max-width: 5rem;
    }

    .img-card-2{
        max-width: 2rem;
      }
  }