.blocos-filtro-qualificacao {
  margin: 0px;
  padding-left: 0px;
  width: 100%;
  min-height: 100px;  
}

.btn-add-campo {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  border: none;
  background-color: var(--dommus-color);
  color: white;  
  margin: 10px 0px;
}
