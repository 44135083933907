.icon-midia{
    display: flex;
    align-items: center;
    font-size: 4rem;
}

.informacoes-card{
    display: flex;
    flex-direction: column;
    gap: 3px;    
    font-size: .9rem;
}

.etapa-section-card{
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
}

.info-card{
    display: flex;
    flex-direction: column;
}

.etapa-oportunidade-card{    
    padding: 5px 10px;
    border-radius: 5px;
}

.dados-card{
    display: flex;
    flex-direction: column;
    gap: 3px;    
    color: #999;
    font-size: 0.9rem;
}

.icon-nao-enviado-card{
    display: flex;
    justify-content: center;    
}

.icon-nao-enviado-card span{
    width: 2.7rem;
    height: 2.7rem;
    background-color: red;
    padding: 5px;
    border-radius: 5px;
}

.arrow-card{
    color: #999;
}

.dados-card .item{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px
}

.dados-card .row-item{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}
